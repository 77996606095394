import { useRef } from "react";
import { UseQueryResult, useQuery, useQueryClient } from "react-query";

import { api, ApiResponse } from "_/utils";
import { Uuid } from "_/types";

const SCOPE = "machines";
const KEYS = {
  all: () => [SCOPE],
  machine: (id: Uuid) => [SCOPE, "detail", id],
  org: (orgId: Uuid) => [SCOPE, "org", orgId],
  events: ({ machineId, from, to, kind, limit, order }: EventQuery) => [
    SCOPE,
    "events",
    machineId,
    from,
    to,
    kind,
    limit,
    order,
  ],
  tempRange: (id: Uuid) => [SCOPE, "tempRange", id],
};

export type Hardware = {
  /**
   * Canonical machine ID.
   */
  id: Uuid;

  /**
   * Machine serial number.
   */
  serial: string;

  /**
   * Date of original manufacture.
   */
  manufactureDate: string;

  /**
   * Machine generation.
   *
   * The machine generation is the most general version specifier for machines and maps to the
   * base architecture used - it can be thought of similar to a semver major. This starts at 2
   * with M2-2018, M2-2020, and M2+ generation machines.
   */
  generation: number;

  /**
   * Machine model name (e.g. M2, M2+ etc.)
   */
  model: string;
};

export type Machine = {
  /**
   * Canonical machine ID.
   */
  id: Uuid;

  /**
   * Hardware backing organization machine instance.
   */
  hardware: Hardware;

  /**
   * Each machine is given its own domain ID which is scoped under another parent domain.
   */
  domainId: Uuid;

  /**
   * Owning organization ID.
   */
  orgId: Uuid;

  /**
   * Human readable name.
   */
  name: string;

  /**
   * Record creation timestamp.
   */
  createdAt: string;

  /**
   * Record update timestamp.
   */
  updatedAt: string;

  /**
   * Last time that the machine was "seen" by the Basis backend.
   */
  lastSeen: string | null;

  /**
   * Machine wired ipv4 LAN address.
   */
  lanAddress?: string;

  /**
   * Machine wireless ipv4 LAN address.
   */
  wlanAddress?: string;

  /**
   * Arbitrary machine metadata.
   *
   * This can be used to store data such as references to external services (e.g. hubspot or
   * balena) and other meta information which should be included.
   */
  metadata?: Record<string, string | number | boolean | null>;
};

async function getMachine(id: Uuid): Promise<Machine> {
  const response = await api.get<ApiResponse<Machine>>(`/machines/${id}`);
  return response.data.data;
}

export function useMachine(id: Uuid) {
  return useQuery({
    placeholderData: null,
    queryKey: KEYS.machine(id),
    queryFn: () => getMachine(id),
  });
}

async function getMachines(): Promise<Machine[]> {
  const response = await api.get<ApiResponse<Machine[]>>("/machines");
  return response.data.data;
}

export function useMachines() {
  return useQuery({
    placeholderData: [],
    queryKey: KEYS.all(),
    queryFn: getMachines,
  });
}

/**
 * Get the members of a specific organization by ID.
 */
async function getMachinesByOrg({
  orgId,
}: {
  orgId: Uuid;
}): Promise<Machine[]> {
  const response = await api.get<ApiResponse<Machine[]>>(
    `/organizations/${orgId}/machines`
  );

  return response.data.data;
}

/**
 * Query hook for [[`getMachinesByOrg`]].
 */
export function useMachinesByOrg(orgId?: Uuid) {
  return useQuery({
    queryKey: orgId ? KEYS.org(orgId) : undefined,
    queryFn: orgId ? () => getMachinesByOrg({ orgId }) : undefined,
  });
}

type MachineTemperatureItem = {
  temperature: number;
  target: number;
};

export type ThermalsEventData = {
  t0: MachineTemperatureItem;
  t1: MachineTemperatureItem;
  bed: MachineTemperatureItem;
  chamber: MachineTemperatureItem;
};

export type ThermalsDataCleaned = {
  t0Temperature: number[];
  t0Target: number[];
  t1Temperature: number[];
  t1Target: number[];
  bedTemperature: number[];
  bedTarget: number[];
  chamberTemperature: number[];
  chamberTarget: number[];
  timestamp: number[];
};

async function getMachineThermals(
  machineId: Uuid,
  // in microseconds
  from: number,
  // in microseconds
  to?: number
) {
  const path = to
    ? `/machines/${machineId}/events?from=${from}&to=${to}&kind=thermals&order=asc`
    : `/machines/${machineId}/events?from=${from}&kind=thermals&order=asc`;
  const response = await api.get<ApiResponse<ThermalEvent[]>>(path);
  return response.data.data;
}

/**
 * Reformat the data from the API into a format that is easier to use in a chart.
 */
function cleanThermalsData(
  newData: ThermalEvent[],
  oldData?: ThermalsDataCleaned
) {
  const cleanedData: ThermalsDataCleaned = oldData || {
    t0Temperature: [],
    t0Target: [],
    t1Temperature: [],
    t1Target: [],
    bedTemperature: [],
    bedTarget: [],
    chamberTemperature: [],
    chamberTarget: [],
    timestamp: [],
  };

  // TODO: change to `toReversed` in 2025
  [...newData].reverse().forEach((event) => {
    const { t0, t1, bed, chamber } = event.data;
    cleanedData.t0Temperature.push(t0.temperature);
    cleanedData.t0Target.push(t0.target);
    cleanedData.t1Temperature.push(t1.temperature);
    cleanedData.t1Target.push(t1.target);
    cleanedData.bedTemperature.push(bed.temperature);
    cleanedData.bedTarget.push(bed.target);
    cleanedData.chamberTemperature.push(chamber.temperature);
    cleanedData.chamberTarget.push(chamber.target);
    cleanedData.timestamp.push(event.timestamp);
  });

  return cleanedData;
}

export function useMachineThermals(
  machine: Machine,
  onData?: (data: ThermalsDataCleaned) => void
) {
  const queryClient = useQueryClient();
  const queryKey = KEYS.tempRange(machine.id);
  const timestamp = useRef<number | null>(null);

  return useQuery(
    Object.assign(
      {
        queryKey: queryKey,
        queryFn: async () => {
          const from =
            timestamp.current || (Date.now() - 60 * 60 * 1000) * 1000; // 1 hour ago
          const newData = await getMachineThermals(machine.id, from);

          const oldData: ThermalsDataCleaned | undefined =
            queryClient.getQueryData(queryKey) || undefined;

          if (newData && newData.length > 0) {
            timestamp.current = newData[newData.length - 1].timestamp;
          }
          return cleanThermalsData(newData, oldData);
        },

        keepPreviousData: true,
        refetchInterval: 1000,
      },
      onData
        ? {
            onSuccess: (data: ThermalsDataCleaned) => onData(data),
            // If a callback is provided, disable notifying / re-rendering the parent component on changes.
            notifyOnChangeProps: [],
          }
        : {}
    )
  );
}

/**
 * Utility type to define the different kinds of telemetry events, and their
 * kind (a string) and payload.
 * */
type TelemetryEventKindAndData =
  | { kind: "thermals"; data: ThermalsEventData }
  | { kind: "stateChange"; data: StateChangeEventData }
  | { kind: "image"; data: ImageEventData }
  | { kind: "startup"; data: StartupEventData }
  | { kind: "log"; data: LogEventData }
  | { kind: "systemProfile"; data: never };

export type TelemetryEvent<K extends TelemetryEventKind = TelemetryEventKind> =
  {
    timestamp: number;
    kind: K;
  } & TelemetryEventKindAndData;

export type TelemetryEventKind = TelemetryEventKindAndData["kind"];

export interface ImageEventData {
  imageId: Uuid;
}

// TODO: Where is this event defined? Does the server actually send it?
export interface LogEventData {
  level: string;
  message: string;
}

export interface StartupEventData {
  networkInfo: {
    lanAddress: string;
    wlanAddress: string | null;
  };
  agentVersion: string;
}

/**
 * The possible states of a machine.
 *
 * Note that "unknown" is not sent by the server. Instead, it's used by the
 * client to indicate when we don't have info from the server about the status
 * of this machine.
 */
export type MachineStatus = StateChangeEventKind | "unknown";

type StateChangeEventKind =
  | "standby"
  | "printing"
  | "paused"
  | "complete"
  | "cancelled"
  | "error";
export interface StateChangeEventData {
  state: MachineStatus;
  fileName: string;
  revisionId: Uuid;
}

type ThermalEvent = TelemetryEvent<"thermals">;
export type ImageEvent = TelemetryEvent<"image">;
export type StartupEvent = TelemetryEvent<"startup">;
export type StageChangeEvent = TelemetryEvent<"stateChange">;

export type EventQuery = {
  machineId: Uuid;
  from?: number;
  to?: number;
  kind?: TelemetryEventKind[];
  limit?: number;
  order?: "asc" | "desc";
};

export function useMachineEvents<T extends TelemetryEventKind>(
  query: EventQuery & { kind: [T] }
): UseQueryResult<TelemetryEvent<T>[]>;
export function useMachineEvents(
  query: EventQuery
): UseQueryResult<TelemetryEvent[]>;
export function useMachineEvents(query: EventQuery) {
  async function getMachineEvents() {
    const from = query.from;
    const to = query.to;
    const limit = query.limit || 1_000;
    const kind = query.kind || [];
    const order = query.order;

    const path = [
      `/machines/${query.machineId}/events`,
      limit ? `?limit=${limit}` : "",
      from ? `&from=${from}` : "",
      to ? `&to=${to}` : "",
      kind.map((k, i) => `&kind[${i}]=${k}`).join(""),
      order ? `&order=${order}` : "",
    ].join("");

    const response = await api.get<ApiResponse<TelemetryEvent[]>>(path);

    return response.data.data;
  }

  return useQuery({
    placeholderData: [],
    queryKey: KEYS.events(query),
    queryFn: getMachineEvents,
  });
}
