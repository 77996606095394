import React, { ComponentProps } from "react";
import { Link as _Link, LinkProps } from "wouter";

import * as S from "./styled";

// Avoid a Webpack warning by re-exporting types separately from runtime code.
// See https://github.com/webpack/webpack/issues/7378
export {
  Router,
  Route,
  Switch,
  Redirect,
  useLocation,
  useRoute,
  useParams,
} from "wouter";
export type { StringRouteParams } from "wouter";

/**
 * Wrap wouter's <Link> component to ensure that an <a> element is always
 * created. This ensures that you can style a Link and its child component
 * without worrying about the styles being squished together in one component at
 * runtime. It's also better for accessibility, and enables <a>-dependent
 * features like "open in new tab" right-click actions in the browser.
 */
export function Link(props: LinkProps) {
  const { to, href, asChild } = props;

  // wouter tries to treat mailto links as regular URLs, and it tries to put
  // them into the browser history stack, which fails. So just use plain links
  // in that case.
  if ((to ?? href)?.toLowerCase().startsWith("mailto:")) {
    // Following react-router's API, wouter's className component can be
    // a function that receives a boolean indicating if the link is active.
    // mailto links are never active, so we can safely pass false.
    const classNameProp = asChild ? undefined : props.className;
    const className =
      typeof classNameProp === "function"
        ? classNameProp(false)
        : classNameProp;

    return (
      <a
        {...props}
        className={className}
        target="_blank"
        rel="noreferrer"
        href={to ?? href}
      />
    );
  }

  return <_Link {...props} />;
}

/**
 * A non-breaking space that doesn't underline on hover. Useful for separating,
 * for example, a project name and revision number badge in a link.
 */
export const NeverUnderlineSpace = () => (
  <S.NeverUnderline>&nbsp;</S.NeverUnderline>
);

type TextLinkProps = LinkProps & {
  variant: ComponentProps<typeof S.TextLink>["$variant"];
};

export const TextLink = (props: TextLinkProps) => {
  const { variant, ...rest } = props;
  return <S.TextLink $variant={variant} {...rest} />;
};
