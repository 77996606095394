import React, { ReactElement } from "react";
import { useRoute } from "_/components/router";
import { t } from "@lingui/macro";

import * as S from "./styled";
import { IconVariant } from "_/components/icon";
import { routeUrls, routes } from "_/routes";

/**
 * Navigation item listed under a group header, representing a complete path.
 */
type NavItem = {
  label: string;
  route: string;
  url: string;
  icon: IconVariant;
};

const NavLink = (props: { navItem: NavItem }): ReactElement => {
  const { label, icon, route, url } = props.navItem;
  const [match, _] = useRoute(route);

  return (
    <S.Link to={url} $active={match}>
      <S.Icon variant={icon} />
      <S.LabelText>{label}</S.LabelText>
    </S.Link>
  );
};

export const Sidebar = (): ReactElement => {
  const navItems: NavItem[] = [
    {
      label: t`common.home`,
      icon: "Home",
      route: routes.index,
      url: routeUrls.index,
    },
    {
      label: t`common.projects`,
      icon: "Projects",
      route: routes.projects.wildcards.index,
      url: routeUrls.projects.index,
    },
    {
      label: t`common.machines`,
      icon: "Machines",
      route: routes.machines.wildcards.index,
      url: routeUrls.machines.index,
    },
  ];

  const bottomNavItems: NavItem[] = [
    // // FIXME: The icon used here is ugly - let's replace it with a better one.
    // // TODO: Re-enable the sidebar link once we're ready for docs to go live.
    // {
    //   label: t`common.docs`,
    //   icon: "MenuBook",
    //   route: routes.docs.wildcards.index,
    //   url: routeUrls.docs.index,
    // },
    {
      label: t`component.sidebar.support`,
      icon: "Support",
      // Dummy route using an invalid URL character so that the mailto link
      // will not be "matched" on and styled as active.
      route: "|",
      url: "mailto:help@aon3d.com",
    },
  ];

  return (
    <S.Wrapper>
      <S.TopNav>
        {navItems.map((navItem, i) => (
          <NavLink navItem={navItem} key={i} />
        ))}
      </S.TopNav>
      <S.BottomNav>
        {bottomNavItems.map((navItem, i) => (
          <NavLink navItem={navItem} key={i} />
        ))}
      </S.BottomNav>
    </S.Wrapper>
  );
};
