import * as provider from "@mdx-js/react";
import * as runtime from "react/jsx-runtime";
import dayjs from "dayjs";
import React, {
  useEffect,
  useState,
  Fragment,
  HTMLProps,
  ReactElement,
} from "react";
import rehypeSlug from "rehype-slug";
import remarkFlexibleToc, { TocItem } from "remark-flexible-toc";
import remarkGfm from "remark-gfm";
import { evaluateSync, EvaluateOptions } from "@mdx-js/mdx";
import { ThemeProvider } from "styled-components";
import { t } from "@lingui/macro";

import { getTheme } from "_/style";
import { docsUrls } from "_/routes";
import { LayoutNode, DocMeta, useDocPage, useDocsManifest } from "_/data/docs";
import { Link, useRoute, useParams } from "_/components/router";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  ThCell,
  Td,
  TableContainer,
} from "_/components/table/styled";

import * as S from "./styled";
import { DocsLink } from "./docs-link";
import { DocsImg } from "./docs-img";
import { Notice } from "./notice";

/**
 * Components to be used in the MDX content.
 *
 * These will override the "default" tags used in the MDX and allow for
 * use of custom react components within the MDX as well (e.g. the `<Notice />`
 * component).
 */
const MDX_COMPONENTS = {
  a: DocsLink,
  hr: S.DocsHr,
  Notice,
  img: DocsImg,
  table: ({ children }: HTMLProps<HTMLTableElement>) => {
    return (
      <TableContainer style={{ margin: "20px 0" }}>
        <Table $columnCount={2} role="grid">
          {children}
        </Table>
      </TableContainer>
    );
  },
  tbody: Tbody,
  thead: Thead,
  th: ({ children }: HTMLProps<HTMLTableHeaderCellElement>) => (
    <Th role="columnheader">
      <ThCell>{children}</ThCell>
    </Th>
  ),
  tr: ({ children }: HTMLProps<HTMLTableRowElement>) => {
    if (Array.isArray(children) && children[0].type.name === "th") {
      return (
        <Tr role="row">
          {children}
          <Th />
        </Tr>
      );
    }

    return (
      <Tr role="row">
        {children}
        <Td role="gridcell" />
      </Tr>
    );
  },
  td: ({ children }: HTMLProps<HTMLTableDataCellElement>) => (
    <Td role="gridcell">{children}</Td>
  ),
};

/**
 * Main top level documentation view component.
 */
export const DocsView = (): ReactElement => {
  const params = useParams();
  const manifestQuery = useDocsManifest();

  // Allow for modifying the page titles and reset to the previous title when
  // the docs page component is unmounted.
  useEffect(() => {
    const originalTitle = document.title;
    return () => {
      document.title = originalTitle;
    };
  }, []);

  if (manifestQuery.isLoading) {
    return (
      <S.LoadingWrapper>
        <S.LoadingSpinner />
      </S.LoadingWrapper>
    );
  }

  // TODO: Improve the error display.
  if (!manifestQuery.data) {
    console.error("No manifest file loaded for docs.");
    return <>404: Documentation manifest not found</>;
  }

  const layout = manifestQuery.data.layout;

  return (
    <S.Wrapper>
      <DocsNavigation layout={layout} />
      <DocsContent slug={params.slug} />
    </S.Wrapper>
  );
};

const DocsContent = ({ slug }: { slug?: string }): ReactElement => {
  const pageQuery = useDocPage(slug);

  if (pageQuery.isLoading) {
    return (
      <S.LoadingWrapper>
        <S.LoadingSpinner />
      </S.LoadingWrapper>
    );
  }

  if (!pageQuery.data) {
    console.error(`No page data loaded for doc. Check the slug "${slug}".`);
    return <>404: Documentation page not found</>;
  }

  const { content, meta } = pageQuery.data;

  return <MdxContent content={content} meta={meta} />;
};

// Initial depth to open the nav items to.
const INITIAL_DEPTH = 1;

/**
 * Helper component for creating and nesting the nav items.
 *
 * The `<NavItem />` component is recursive and will render itself for
 * each entry to be displayed in the navigation, nesting deeper for each
 * level in the docs manifest.
 */
const NavItem = ({
  item,
  depth,
}: {
  item: LayoutNode;
  depth: number;
}): ReactElement => {
  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(depth < INITIAL_DEPTH);
  const [preload, setPreload] = useState(false);
  const [timeoutId, setTimeoutId] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);
  const route = docsUrls.page(item.slug);
  const [match, _] = useRoute(route);

  // Debounce preloading so that mousing over the nav doesn't load all the
  // content unless there's a more definite intent to click an item.
  useEffect(() => {
    if (isHovered && !timeoutId) {
      const id = setTimeout(() => setPreload(true), 50);
      setTimeoutId(id);
    } else if (!isHovered && timeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(null);
    }
  }, [setTimeoutId, setPreload, timeoutId, isHovered]);

  const items = item.children?.map((itm) => (
    <NavItem key={itm.slug} item={itm} depth={depth + 1} />
  ));

  // Helper component used simply to preload doc content when the user
  // hovers over a nav item. This returns nothing and is only used to call
  // the `useDocPage` hook to preload the content.
  const Preload = () => {
    useDocPage(item.slug);
    return null;
  };

  const expandArrow =
    items.length > 0 ? (
      <S.DocsNavItemArrow
        onClick={(e) => {
          e.stopPropagation();
          setIsExpanded(!isExpanded);
        }}
        $expanded={isExpanded}
      />
    ) : null;

  return (
    <Fragment key={item.slug}>
      {preload ? <Preload /> : null}
      <S.DocsNavItem
        onClick={() => setIsExpanded(!(match && isExpanded))}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        $depth={depth}
        $active={match}
      >
        {expandArrow}
        <Link to={route}>{item.title}</Link>
      </S.DocsNavItem>
      {isExpanded ? items : null}
    </Fragment>
  );
};

/**
 * Nav component for selecting docs pages to view (i.e. the left sidebar).
 */
const DocsNavigation = (props: { layout: LayoutNode[] }): ReactElement => {
  const items = props.layout.map((itm) => (
    <NavItem key={itm.slug} item={itm} depth={0} />
  ));

  return (
    <S.DocsNav>
      <h3>{t`common.docs`}</h3>
      <S.DocsNavList>{items}</S.DocsNavList>
    </S.DocsNav>
  );
};

/**
 * MDX content area and table of contents.
 */
const MdxContent = (props: {
  content: string;
  meta: DocMeta;
}): ReactElement => {
  document.title = `Basis | ${props.meta.title || t`common.docs`}`;

  // MDX file table of contents is parsed and stored in this array
  // by the `remarkFlexibleToc` plugin. It must be an empty array
  // and will be filled when `evaluateSync` is run.
  const tocRef: TocItem[] = [];

  const options = {
    ...provider,
    ...runtime,
    remarkPlugins: [remarkGfm, [remarkFlexibleToc, { tocRef }]],
    rehypePlugins: [rehypeSlug],
    useMDXComponents: () => MDX_COMPONENTS,
  } as EvaluateOptions;

  // No need to memoize this since this component will only be rendered
  // if or when the content has changed.
  const { default: Content } = evaluateSync(props.content, options);
  const modifiedAt = dayjs(props.meta.modifiedAt);

  const theme = getTheme("light");

  return (
    <S.MdxContentWrapper>
      <ThemeProvider theme={theme}>
        <S.MainContent>
          <h1>{props.meta.title}</h1>
          <Content />
        </S.MainContent>
      </ThemeProvider>
      <S.TocContent>
        <h4>{t`docs-view.page-toc`}</h4>
        <PageToc toc={tocRef} />
        <S.ModifiedAt>{t`docs.last-modified ${modifiedAt.fromNow()}`}</S.ModifiedAt>
      </S.TocContent>
    </S.MdxContentWrapper>
  );
};

/**
 * Document table of contents displayed on the right side of the page.
 */
const PageToc = ({ toc }: { toc: TocItem[] }): ReactElement => {
  // Calculate the minimum depth of the TOC items to use for the display
  // indentation level. Depth may vary depending on the headings used so can
  // sometimes start at 1, and other times start at 2 or 3.
  const depths = toc.map((item) => item.depth);
  const minDepth = Math.min(...depths);

  const tocItems = toc.map((item) => (
    <S.PageTocItem key={item.value} $depth={item.depth - minDepth}>
      <a href={item.href}>{item.value}</a>
    </S.PageTocItem>
  ));

  return <S.PageToc>{tocItems}</S.PageToc>;
};
