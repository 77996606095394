import React, { ReactElement, useEffect, useState } from "react";
import * as S from "./styled";
import { SliderSize } from "./styled";

type SliderProps = {
  /**
   * Minimum value of the slider.
   */
  min?: number;
  /**
   * Maximum value of the slider.
   */
  max?: number;
  /**
   * Starting values of the slider thumbs.
   * The number of values determines the number of thumbs.
   */
  defaultValue?: number[];
  /**
   * Values of the slider thumbs.
   */
  value?: number[];
  /**
   * The amount to increment/decrement the slider value when the thumb is moved.
   */
  step?: number;
  /**
   * Callback function that is called when the slider value changes.
   */
  onChange?: (value: number[]) => void;
  /**
   * The size of the slider.
   */
  size?: SliderSize;
  /**
   * True if the slider should be oriented vertically.
   */
  vertical?: boolean;
};

export const Slider = ({
  min,
  max,
  defaultValue = [],
  step,
  onChange,
  size = "small",
  vertical = false,
}: SliderProps): ReactElement => {
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <S.SliderRoot
      min={min}
      max={max}
      value={value}
      step={step}
      onValueChange={(value) => {
        setValue(value);
        onChange?.(value);
      }}
      $size={size}
      orientation={vertical ? "vertical" : "horizontal"}
    >
      <S.SliderTrack $size={size}>
        <S.SliderRange />
      </S.SliderTrack>
      {value?.map((_value, index) => (
        <S.SliderThumb key={index} $size={size} />
      ))}
    </S.SliderRoot>
  );
};
