import { useQuery, UseQueryResult, UseQueryOptions } from "react-query";

import { api, ApiResponse } from "_/utils";
import { Uuid } from "_/types";

const SCOPE = "blobs";
const KEYS = {
  all: () => [SCOPE, "all"] as const,
  content: ({ id }: { id: Uuid }) => [SCOPE, id, "content"] as const,
  detail: ({ id }: { id: Uuid }) => [SCOPE, id, "detail"] as const,
};

export type BlobKind = "gcode" | "stl" | "unknown";

export type BlobRecord = {
  id: Uuid;
  name: string;
  kind: BlobKind;
  domainId: Uuid;
  createdAt: string;

  /**
   * Total size of this blob. If the blob is compressed, it will be the size of
   * the blob after decompression.
   */
  uncompressedSize: number;

  /**
   * If the blob is compressed, this will be the number of bytes that will be
   * downloaded. If the blob is not compressed, then this will be `undefined`.
   */
  storedSize: number;

  /**
   * If we compressed the blob before storing it in our cloud storage, then
   * "gzip" is returned. If we didn't compress the file (for example, very small
   * files and PNG/JPEG images are not compressed by us) then "none" is
   * returned.
   */
  compressionKind: "none" | "gzip";
};

const blobDownloadPath = (id: Uuid) => `/blobs/${id}/contents`;

export function blobDownloadURL(id: Uuid): string {
  return `/api/v0${blobDownloadPath(id)}`;
}

export async function uploadImageBlob(
  file: File,
  metadata: {
    name: string;
    kind: "png" | "jpg";
    domainId?: Uuid;
  }
): Promise<BlobRecord> {
  const response = await api.postForm<ApiResponse<BlobRecord>>("/images", {
    content: file,
    metadata: JSON.stringify(metadata),
  });
  return response.data.data;
}

async function fetchBlobContents(
  blobRecord: BlobRecord | undefined,
  pathFunc: (id: Uuid) => string,
  setProgress?: (progress: number) => void,
  signal?: AbortSignal
): Promise<ArrayBuffer | undefined> {
  if (!blobRecord) return;

  const { id, uncompressedSize } = blobRecord;

  let timeout: ReturnType<typeof setTimeout> | undefined;

  try {
    // Initialize progress.
    setProgress?.(0);
    const response = await api.get<ArrayBuffer | undefined>(pathFunc(id), {
      responseType: "arraybuffer",
      onDownloadProgress: (progressEvent) => {
        // Browsers report progress differently for compressed blobs:
        // * Chrome/Safari: total is not provided, and current is the uncompressed size.
        // * Firefox: both total and current are compressed sizes.
        const total = progressEvent.total
          ? progressEvent.total // Firefox
          : uncompressedSize; // Chrome/Safari
        const current = progressEvent.loaded;
        if (total) {
          const progress = current / total;

          // Update progress after 10ms. This prevents progress jumping to 100% when loading an error response.
          timeout = setTimeout(() => setProgress?.(progress), 10);
        }
      },
      signal,
    });

    return response.data;
  } catch (e) {
    clearTimeout(timeout);
    setProgress?.(0);

    return Promise.reject(e);
  }
}

export function useBlobRecord(id: Uuid): UseQueryResult<BlobRecord> {
  const config: UseQueryOptions<BlobRecord> = {
    queryKey: KEYS.detail({ id }),
    queryFn: async () => {
      const response = await api.get<ApiResponse<BlobRecord>>(`/blobs/${id}`);
      return response.data.data;
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  };

  return useQuery(config);
}

/**
 * Create an async iterator from a ReadableStream. This enables `for await of`
 * to work on a stream before this feature is fully rolled out across browsers.
 * (Currently only Firefox supports it natively.)
 */
async function* asyncStreamIterator<T>(stream: ReadableStream<T>) {
  const reader = stream.getReader();
  try {
    while (true) {
      const { done, value } = await reader.read();
      if (done) return;
      yield value;
    }
  } finally {
    reader.releaseLock();
  }
}

/**
 * Decompress an array of Uint8Array chunks into a single decompressed blob.
 *
 * @param compressedChunks An array of Uint8Array chunks that, when concatenated, yield a full compressed response body.
 * @returns an ArrayBuffer containing the decompressed data.
 */
async function decompressChunks(compressedChunks: Uint8Array[]) {
  const decompressedStream: ReadableStream = new Blob(compressedChunks)
    .stream()
    .pipeThrough(new DecompressionStream("gzip"));

  const decompressedChunks = [] as Uint8Array[];
  for await (const chunk of asyncStreamIterator(decompressedStream)) {
    decompressedChunks.push(chunk as unknown as Uint8Array);
  }

  const combined = combineArrays(decompressedChunks);
  return combined.buffer;
}

function combineArrays(arrays: Uint8Array[]): Uint8Array {
  // Create a new array large enough to hold all the input arrays.
  const totalLength = arrays.reduce((acc, val) => acc + val.length, 0);
  const combined = new Uint8Array(totalLength);

  // Copy each array into the combined array at the correct offset.
  let offset = 0;
  arrays.forEach((array) => {
    combined.set(array, offset);
    offset += array.length;
  });

  return combined;
}

class OperationTiming {
  constructor(key?: number) {
    if (key !== undefined) this.key = key;
  }
  start() {
    this.startTime = Date.now();
  }
  end() {
    if (!this.startTime) throw new Error("Timing not started");
    this.endTime = Date.now();
    this.total = (this.endTime - this.startTime) / 1000;
  }
  get mbPerSec() {
    return this.total && this.bytes ? this.bytes / this.total / 1e6 : undefined;
  }
  startTime?: number;
  endTime?: number;
  total?: number;
  bytes?: number;
  key?: number;
}

class OneReadTiming extends OperationTiming {
  fetch = new OperationTiming();
  allReads = new OperationTiming();
  eachRead = [] as OperationTiming[];
}

class ParallelBlobTiming extends OperationTiming {
  allChunks = new OperationTiming();
  chunks = [] as OneReadTiming[];
  decompress = new OperationTiming();
}

/**
 * Download a large, compressed file over multiple parallel HTTP connections by
 * loading a range of bytes from each one, and stitching together the result at
 * the end.
 *
 * The remote file is gzip-compressed but (IMPORTANT!) it lacks the
 * Content-Encoding header, so the browser doesn't decompress it for us. We have
 * to do that ourselves. To let the server know that we don't want the
 * Content-Encoding header, we pass a custom header. And to make caching work,
 * we also pass a different query string param for each chunk.
 */
export async function fetchBlobContentsParallel(
  blobRecord: BlobRecord | undefined,
  pathFunc: (id: Uuid) => string,
  setProgress?: (progress: number) => void,
  signal?: AbortSignal
): Promise<ArrayBuffer | undefined> {
  if (!blobRecord) return;

  const { id, storedSize, compressionKind } = blobRecord;

  if (!storedSize) throw new Error(`Blob size is ${String(storedSize)}`);

  // Download smaller blobs with less concurrency. Each concurrent request adds
  // overhead, so we want to avoid too much parallelism for small files. Limit
  // total number of requests to 4 to avoid exceeding the typical browser's
  // maximum of 6 connections per host.
  const requestCount = Math.max(Math.min(Math.floor(storedSize / 5e6), 4), 1);

  let timeout: ReturnType<typeof setTimeout> | undefined;

  const timing = new ParallelBlobTiming();
  timing.start();
  timing.bytes = storedSize;

  try {
    // Initialize progress.
    setProgress?.(0);

    if (!storedSize) {
      throw new Error(
        `Blob ${id} has no compressed or uncompressed size in its manifest, so we can't download it`
      );
    }

    // Communicate progress percentage back to the UI
    let totalDownloaded = 0;
    const onChunkProgress = (bytesInChunk: number) => {
      if (storedSize) {
        totalDownloaded += bytesInChunk;
        const progress = totalDownloaded / storedSize;

        // Update progress after 10ms. This prevents progress jumping to 100%
        // when loading an error response.
        timeout = setTimeout(() => setProgress?.(progress), 10);
      }
    };

    const oneRequest = async (_: never, i: number) => {
      const chunkTiming = new OneReadTiming(i);
      timing.chunks.push(chunkTiming);
      chunkTiming.start();

      const isLastChunk = i === requestCount - 1;
      const chunkSize = Math.floor(storedSize / requestCount);
      const start = chunkSize * i;
      const end = isLastChunk ? storedSize - 1 : start + chunkSize - 1;

      chunkTiming.fetch.start();
      // If this is the last chunk and the file is not compressed, leave the
      // end index empty. This ensures that if print data has been appended to
      // then we'll get the latest bytes.
      const endIndex = isLastChunk && compressionKind === "none" ? "" : end;
      const customRangeHeader = `bytes=${start}-${endIndex} ${compressionKind}`;

      // To enable caching to work properly, we need to add a mux query string
      // param that duplicates the value of the X-AON3D-Range header. See
      // https://github.com/aon3d/holonome/pull/885 for details.
      const url = `/api/v0${pathFunc(id)}?mux=${encodeURIComponent(customRangeHeader)}`;

      const response = await fetch(url, {
        headers: {
          "X-AON3D-Range": customRangeHeader,
        },
        signal,
      });
      chunkTiming.fetch.end();

      if (!response.ok) {
        throw new Error(
          `Failed to get download response for ${blobRecord.id} ${response.status} ${response.statusText}`
        );
      }

      if (!response.body) {
        throw new Error(
          `Failed to get response body for ${blobRecord.id} ${response.status} ${response.statusText}`
        );
      }

      const contentLengthHeader = response.headers.get("Content-Length");
      if (contentLengthHeader == null) {
        throw new Error("Expected Content-Length header");
      }

      const bytesInChunk = parseInt(contentLengthHeader, 10);
      const data = new Uint8Array(bytesInChunk);
      chunkTiming.bytes = bytesInChunk;

      chunkTiming.allReads.start();
      chunkTiming.eachRead.push(new OperationTiming(0));
      chunkTiming.eachRead[0].start();
      let readIndex = 0;
      let chunkBytesDownloaded = 0;
      for await (const oneReadBytes of asyncStreamIterator(response.body)) {
        chunkTiming.eachRead[readIndex].end();
        chunkTiming.eachRead[readIndex].bytes = oneReadBytes.byteLength;

        readIndex++;
        chunkTiming.eachRead.push(new OperationTiming(readIndex));
        chunkTiming.eachRead[readIndex].start();

        data.set(oneReadBytes, chunkBytesDownloaded);
        chunkBytesDownloaded += oneReadBytes.byteLength;
        onChunkProgress(oneReadBytes.byteLength);
      }

      chunkTiming.eachRead[readIndex].end(); // End the last read
      chunkTiming.allReads.end();
      chunkTiming.end();
      return data;
    };

    // Create multiple requests to download the blob in parallel
    const requests = Array.from({ length: requestCount }, oneRequest);
    timing.allChunks.start();
    const chunkData = await Promise.all(requests);
    timing.allChunks.end();
    timing.allChunks.bytes = chunkData.reduce(
      (acc, chunk) => acc + chunk.byteLength,
      0
    );

    let buffer: ArrayBuffer;
    if (compressionKind === "gzip") {
      // Decompress the data after it's been reassembled.
      timing.decompress.start();
      buffer = await decompressChunks(chunkData);
      timing.decompress.end();
      timing.decompress.bytes = buffer.byteLength;
    } else {
      // The source data does not need to be decompressed.
      const combined = combineArrays(chunkData);
      buffer = combined.buffer;
    }
    timing.end();
    console.dir(timing);

    return buffer;
  } catch (e) {
    clearTimeout(timeout);
    setProgress?.(0);

    return Promise.reject(e);
  }
}

export function useBlobContents(
  blobRecord: BlobRecord | undefined,
  setProgress?: (progress: number) => void,
  parallel: boolean = false
): UseQueryResult<ArrayBuffer | undefined> {
  const fetchFn = parallel ? fetchBlobContentsParallel : fetchBlobContents;
  const config: UseQueryOptions<ArrayBuffer | undefined> = {
    queryKey: blobRecord?.id ? KEYS.content({ id: blobRecord?.id }) : undefined,
    queryFn: async ({ signal }) => {
      return await fetchFn(blobRecord, blobDownloadPath, setProgress, signal);
    },
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  };

  return useQuery(config);
}
