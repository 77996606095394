import React from "react";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";
import { createRoot } from "react-dom/client";
import { getWebInstrumentations, initializeFaro } from "@grafana/faro-react";

import { App } from "_/components/app";
import { Wrappers } from "_/components/wrappers";

// TODO: Fix this to use a proper version.
const getVersion = () => {
  return "DEVELOPMENT";
};

function initFaro() {
  initializeFaro({
    url: __GRAFANA_FARO_ENDPOINT__,
    app: {
      name: "Basis Client",
      version: getVersion(),
      environment: __ENVIRONMENT__,
    },
    instrumentations: [
      ...getWebInstrumentations(),
      new TracingInstrumentation(),
    ],
  });
}

function initReact() {
  const ROOT_ELEMENT = document.getElementById("app-root");

  if (ROOT_ELEMENT === null) {
    const error = [
      "Document root does not have a mount point. If you are seeing this error, ",
      "please email dev@aon3d.com and we will resolve the issue immediately. ",
      "This error should never occur.",
    ].join("");
    document.body.textContent = error;
  } else {
    const Root = () => {
      return (
        <Wrappers>
          <App />
        </Wrappers>
      );
    };

    const root = createRoot(ROOT_ELEMENT);
    root.render(<Root />);
  }
}

function main() {
  initFaro();
  initReact();
}

main();
