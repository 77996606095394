import { atom, Atom, useAtom } from "jotai";
import { atomWithStorage, createJSONStorage } from "jotai/utils";

import { GCodePrintData } from "_/components/view-loaders/gcode/gcode-print";

import { Uuid, Update, Tuple, Triple } from "_/types";

/*
 * Hylo Bed Dimensions in mm
 */
export const BED_DIMENSIONS = {
  width: 680,
  height: 480,
};

/**
 * View modes for the 3D viewport.
 *
 * Each view mode enum type must have an accompanying react component which
 * will handle the canvas rendering and any control of settings.
 */
export enum ViewMode {
  Gcode = "gcode", // prepare
  Sim = "sim", // optimize
  Print = "print", // analyze
}

// View: the content of the 3D viewport for a specific project view mode (prepare, optimize, analyze)
export type View<T extends ViewMode> = {
  mode: T;
  id: Uuid;
  data: ViewData<T>;
  controlsAtom: Atom<ViewControls<T>>;
};

// Atom for the current content of the 3D viewport
export const viewAtom = atom<View<ViewMode> | null>(null);

// Data needed to render a Gcode file in the "Prepare" tab
export type GcodeViewData = {
  printData: GCodePrintData;
};

// Type for z range and sensor range information, used to configure controls
export type SensorPointCloudInfo = {
  zRange: Tuple<number>;
  extrudedZRange: Tuple<number>;
  sensorValueRange: Tuple<number>;
  extrudedSensorValueRange: Tuple<number>;
};

// Type for an individual sensor data stream, to be toggled by the point cloud renderer
export type SensorPointCloudSource = {
  name: string;
  positions: Float32Array;
  sensorValues: Float32Array;
};

// Data needed to render print sensor data in the "Analyze" tab
export type PrintViewData = {
  positions: Float32Array;
  sensorValues: Float32Array;
  extrusionValues: Int32Array;
  info: SensorPointCloudInfo;
};

// Data for an individual sim result stream (ie, "analysis" or "optimized" data)
export type SimResultSource = {
  positions: Float32Array;
  layerWelding: Float32Array;
  previousLayerTemperature: Float32Array;
};

// Data needed to render sim data in the "Optimize" tab
export type SimViewData = {
  analysis: SimResultSource;
  optimized?: SimResultSource;
};

export type ViewData<T extends ViewMode> = T extends ViewMode.Gcode
  ? GcodeViewData
  : T extends ViewMode.Print
    ? PrintViewData
    : T extends ViewMode.Sim
      ? SimViewData
      : undefined;

// Control config for a Gcode file in the "Prepare" tab
export type GcodeViewControls = {
  layer: { range: Tuple<number>; value: Tuple<number> };
};

// Control config for print sensor data in the "Analyze" tab
export type PrintViewControls = {
  zRange: {
    range: Tuple<number>;
    extrudedRange: Tuple<number>;
    value: Tuple<number>;
  };
  valueRange: {
    range: Tuple<number>;
    extrudedRange: Tuple<number>;
    value: Tuple<number>;
  };
  colorRange: {
    range: Tuple<number>;
    value: Tuple<number>;
  };
  pointSize: { range: Tuple<number>; value: number };
  showNonExtrusionMotion: boolean;
};

// Current values for a sim snapshot's point cloud controls
export type SimPointCloudControlsValue = {
  valueRange: Tuple<number>;
  colorRange: Tuple<number>;
};

// Data ranges for a sim bundle's result streams, used to configure controls
export type SimInfo = {
  zRange: Tuple<number>;
  layerWelding: Tuple<number>;
  previousLayerTemperature: Tuple<number>;
};

// Control config for sim data in the "Optimize" tab
export type SimViewControls = {
  source: "analysis" | "optimized";
  snapshot: "layerWelding" | "previousLayerTemperature";
  includesOptimized: boolean; // True if the sim data includes optimized data
  ranges: SimInfo;
  values: {
    zRange: Tuple<number>;
    layerWelding: SimPointCloudControlsValue;
    previousLayerTemperature: SimPointCloudControlsValue;
  };
};

export type ViewControls<T extends ViewMode> = T extends ViewMode.Gcode
  ? GcodeViewControls
  : T extends ViewMode.Print
    ? PrintViewControls
    : T extends ViewMode.Sim
      ? SimViewControls
      : undefined;

export type ViewerState = {
  cameraTarget: Triple<number>;
  cameraPosition: Triple<number>;
  cameraFov: number;
  cameraZoom: number;
  cameraOrtho: boolean;
  showBed: boolean;
  showStats: boolean;
};

export const INITIAL_VIEWER_STATE: ViewerState = {
  cameraTarget: [BED_DIMENSIONS.width / 2, BED_DIMENSIONS.height / 2, 0],
  cameraPosition: [650, -600, 650],
  cameraFov: 45,
  cameraZoom: 1.5,
  cameraOrtho: true,
  showStats: false,
  showBed: true,
};

// For each project, we store two viewerStates: one for the Prepare and Analyze tabs and one
// for the Optimize tab. This split is made to accommodate for the placement of the print
// in the Optimize tab, which is different from the one shared by the Prepare and Analyze tabs.
//
// If at some point this difference in placement is resolved, we can simplify the state to
// only store one viewerState per project.
type ViewerStates = {
  prepareAnalyze: ViewerState;
  optimize: ViewerState;
};

// Holds the viewer state for projects that have been viewed in the current session
type ViewerSessionState = Record<Uuid, ViewerStates>;

// Storage atom for the entire session state
// The atom is key is "separatedViewerSessionState" to reflect the separation of viewer states
// for the Prepare/Analyze and Optimize tabs. If the states are merged in the future, this key
// can be changed to "viewerSessionState".
const viewerSessionStateAtom = atomWithStorage<ViewerSessionState>(
  "separatedViewerSessionState",
  {},
  createJSONStorage(() => sessionStorage)
);

// Custom hook to get and set the viewer state for a specific project
export const useViewerState = (
  projectId: Uuid,
  viewerStateMode: "optimize" | "prepareAnalyze"
): [ViewerState, (update: Update<ViewerState>) => void] => {
  const [sessionState, setSessionState] = useAtom(viewerSessionStateAtom);

  // Return the recorded viewer state for the project ID if it exists, otherwise return the initial state
  const viewerStates = sessionState[projectId] ?? {
    prepareAnalyze: INITIAL_VIEWER_STATE,
    optimize: INITIAL_VIEWER_STATE,
  };

  const viewerState = viewerStates[viewerStateMode];

  // Function to update the viewer state for the given project ID
  // Works the same way setState does for React's `useState`
  const setViewerState = (update: Update<ViewerState>) => {
    setSessionState((prevSessionState) => {
      const isFunction = typeof update === "function";

      const prevState = prevSessionState[projectId] ?? {
        prepareAnalyze: INITIAL_VIEWER_STATE,
        optimize: INITIAL_VIEWER_STATE,
      };

      const prevViewerState = prevState[viewerStateMode];

      const updatedViewerState = isFunction ? update(prevViewerState) : update;

      const updatedViewerStates = {
        ...prevState,
        [viewerStateMode]: updatedViewerState,
      };

      return {
        ...prevSessionState,
        [projectId]: updatedViewerStates,
      };
    });
  };

  return [viewerState, setViewerState];
};
