// Use non-breaking space if requested. Useful for contexts like dropdowns,
// where we don't want the date and time to wrap. But in table cells, should
// allow wrapping (default behavior) for better display on smaller screens.
export const NON_BREAKING_SPACE = String.fromCharCode(0x00a0);

// Ensure that dates are never broken on the dash character.
export const NON_BREAKING_DASH = String.fromCharCode(0x2011);

export const UNKNOWN_VALUE_STRING = `${NON_BREAKING_DASH}${NON_BREAKING_DASH}`;

const HEX = "[0-9a-fA-F]";
export const UUID_REGEX = `${HEX}{8}\\b-${HEX}{4}\\b-${HEX}{4}\\b-${HEX}{4}\\b-${HEX}{12}`;
