import styled, { css } from "styled-components";

import { Icon as _Icon } from "_/components/icon";
import { Tooltip as _Tooltip } from "_/components/tooltip";

const invalidStyle = css`
  color: ${(p) => p.theme.input.invalid.text};
`;

export const Label = styled.label`
  width: 100%;
`;

export const LabelContainer = styled.div`
  width: 100%;
  font-size: 14px;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
`;
export const Hint = styled.span<{ $invalid?: boolean }>`
  font-size: 14px;

  ${(p) => p.$invalid && invalidStyle}
`;

export const InfoIcon = styled(_Icon)`
  font-size: 20px;
`;

export const HintWrapper = styled.div`
  width: 100%;
`;
