import React, { ReactElement, useEffect, useState, useMemo } from "react";
import { useRoute } from "_/components/router";
import { t } from "@lingui/macro";
import { assocPath, pipe } from "ramda";
import { Vector3 } from "three";
import { atom, useAtom, PrimitiveAtom } from "jotai";

import {
  useAttemptDatasetManifest,
  useAttemptData,
  isViewableAttemptData,
  Project,
  Attempt,
} from "_/data/projects";
import { imageURL, thumbURL } from "_/data/images";
import { useMachineEvents, ImageEvent } from "_/data/machines";

import {
  SensorPointCloudInfo,
  viewAtom,
  ViewMode,
  PrintViewControls,
} from "_/state/viewer";

import { Slider } from "_/components/slider";
import { ProgressBar } from "_/components/progress-bar";
import { ViewerOverlayNotice } from "_/components/modal";
import { projectRoutes } from "_/routes";
import { LabeledSlider } from "../sliders";

import { useTimer } from "_/utils";

import { Uuid, Tuple } from "_/types";

import {
  MotionData,
  ForceData,
  PrintDataRecords,
  bufferToRecords,
} from "./parseBin";

import * as S from "./styled";

type ImageData = {
  timestamp: number;
  id: string;
};

type ImageDataWithZ = ImageData & { z: number };

// Type for the elements of the sensor data point cloud
type SensorPoint = {
  x: number;
  y: number;
  z: number;
  /**
   * Active toolhead at the time of the data point.
   */
  tool: number;
  /**
   * Extrusion value at the time of the data point.
   */
  e: number;
  sensorValue: number;
};

// Creates the point cloud buffer attribute arrays from the motion data and a single sensor data stream
function constructSensorPointCloud(
  motionData: PrintDataRecords<MotionData>,
  forceData: PrintDataRecords<ForceData>[]
): {
  positions: Float32Array;
  sensorValues: Float32Array;
  extrusionValues: Int32Array;
  info: SensorPointCloudInfo;
} {
  const sensorPoints: SensorPoint[] = [];

  const toolForceDataIndices = [0, 0];

  let minZ = Infinity;
  let maxZ = -Infinity;

  let minExtrudedZ = Infinity;
  let maxExtrudedZ = -Infinity;

  let minSensorValue = Infinity;
  let maxSensorValue = -Infinity;

  let minExtrudedSensorValue = Infinity;
  let maxExtrudedSensorValue = -Infinity;

  const startVertex = new Vector3();
  const endVertex = new Vector3();
  const interpolatedVertex = new Vector3();

  // Take all the force data points that occur before the next motion data point and after the previous one,
  // and give them a position that is linearly interpolated between the two motion data points.
  for (let i = 1; i < motionData.length; i++) {
    const start = motionData.at(i - 1);
    const end = motionData.at(i);

    // Jump ahead to first force data point for the segment.
    forceData.forEach((toolForceData, i) => {
      const len = toolForceData.length;
      while (
        toolForceDataIndices[i] < len &&
        toolForceData.at(toolForceDataIndices[i]).timestamp < start.timestamp
      ) {
        toolForceDataIndices[i]++;
      }
    });

    // If the tool is the same at the start and end of the segment, we consider the segment to be a movement of this tool.
    if (start.tool === end.tool) {
      const activeTool = start.tool;

      // Interpolate data points until we reach the end of the segment.
      const activeToolForceData = forceData[activeTool];
      const len = activeToolForceData.length;
      let dataPoint: ForceData;
      while (
        toolForceDataIndices[activeTool] < len &&
        (dataPoint = activeToolForceData.at(toolForceDataIndices[activeTool]))
          .timestamp < end.timestamp
      ) {
        const t =
          (dataPoint.timestamp - start.timestamp) /
          (end.timestamp - start.timestamp);

        // Check that the data point is part of a segment with  non-zero xy movement
        const xyMovement = start.x !== end.x || start.y !== end.y;

        // If the data point is within the segment bounds, interpolate the position of the data point and add it to the point cloud
        if (t >= 0 && t <= 1) {
          startVertex.set(start.x, start.y, start.z);
          endVertex.set(end.x, end.y, end.z);

          interpolatedVertex.lerpVectors(startVertex, endVertex, t);

          // Check whether the interpolated point is on or above the z=0 plane (the bed surface)
          const nonNegativeZ = interpolatedVertex.z >= 0;

          // If there is either no xy movement or the z value is negative, convert extruding points
          // to non-extruding ones in order to hide them from the extrusion visualization
          const e =
            !(xyMovement && nonNegativeZ) && start.e === 1 ? -1 : start.e;

          // Only add the point to the point cloud if it is on or above the z=0 plane
          const sensorPoint: SensorPoint = {
            x: interpolatedVertex.x,
            y: interpolatedVertex.y,
            z: interpolatedVertex.z,
            tool: activeTool,
            e,
            sensorValue: dataPoint.sensorValue,
          };

          // Update z range and sensor value range for the current point.
          minZ = Math.min(sensorPoint.z, minZ);
          maxZ = Math.max(sensorPoint.z, maxZ);

          if (sensorPoint.e > 0) {
            minExtrudedZ = Math.min(sensorPoint.z, minExtrudedZ);
            maxExtrudedZ = Math.max(sensorPoint.z, maxExtrudedZ);

            minExtrudedSensorValue = Math.min(
              sensorPoint.sensorValue,
              minExtrudedSensorValue
            );
            maxExtrudedSensorValue = Math.max(
              sensorPoint.sensorValue,
              maxExtrudedSensorValue
            );
          }

          minSensorValue = Math.min(sensorPoint.sensorValue, minSensorValue);
          maxSensorValue = Math.max(sensorPoint.sensorValue, maxSensorValue);

          sensorPoints.push(sensorPoint);
        }

        toolForceDataIndices[activeTool]++;
      }
    }

    // Update the indices for the force data streams to the next motion data point
    forceData.forEach((toolForceData, i) => {
      const len = toolForceData.length;
      while (
        toolForceDataIndices[i] < len &&
        toolForceData.at(toolForceDataIndices[i]).timestamp < end.timestamp
      ) {
        toolForceDataIndices[i]++;
      }
    });
  }

  // Create TypedArrays from the array of SensorPoint objects
  const positions = new Float32Array(sensorPoints.length * 3);
  const sensorValues = new Float32Array(sensorPoints.length);
  const extrusionValues = new Int32Array(sensorPoints.length);

  for (let i = 0; i < sensorPoints.length; i++) {
    const sensorPoint = sensorPoints[i];
    positions[i * 3] = sensorPoint.x;
    positions[i * 3 + 1] = sensorPoint.y;
    positions[i * 3 + 2] = sensorPoint.z;
    sensorValues[i] = sensorPoint.sensorValue;
    extrusionValues[i] = sensorPoint.e;
  }

  return {
    positions,
    sensorValues,
    extrusionValues,
    info: {
      zRange: [minZ, maxZ],
      extrudedZRange: [
        minExtrudedZ === Infinity ? minZ : minExtrudedZ,
        maxExtrudedZ === -Infinity ? maxZ : maxExtrudedZ,
      ], // If there are no extruded points, use the regular z range
      sensorValueRange: [minSensorValue, maxSensorValue],
      extrudedSensorValueRange: [
        minExtrudedSensorValue === Infinity
          ? minSensorValue
          : minExtrudedSensorValue,
        maxExtrudedSensorValue === -Infinity
          ? maxSensorValue
          : maxExtrudedSensorValue,
      ], // If there are no extruded points, use the regular sensor value range
    },
  };
}

function interpolateZValuesForImageTimestamps(
  motionData: PrintDataRecords<MotionData>,
  imageData: ImageData[]
) {
  // Add z values to the image data by interpolating between the motion data points
  const imageDataWithZ: ImageDataWithZ[] = [];

  if (motionData.length === 0) {
    return imageDataWithZ;
  }

  let motionIndex = 0;

  imageData.forEach((image) => {
    while (
      motionData.at(motionIndex).timestamp < image.timestamp &&
      motionIndex < motionData.length - 1
    ) {
      motionIndex++;
    }

    // If the image is before the first motion data point, use the first motion data point's z value.
    if (motionIndex === 0) {
      imageDataWithZ.push({ ...image, z: motionData.at(0).z });
    } else {
      const start = motionData.at(motionIndex - 1);
      const end = motionData.at(motionIndex);

      const t =
        (image.timestamp - start.timestamp) / (end.timestamp - start.timestamp);

      const interpolatedZ = start.z + (end.z - start.z) * t;

      imageDataWithZ.push({ ...image, z: interpolatedZ });
    }
  });

  return imageDataWithZ;
}

export function binarySearchImageData(
  imageDataWithZ: ImageDataWithZ[],
  z: number
): ImageDataWithZ | null {
  const numImages = imageDataWithZ.length;

  let left = 0;
  let right = numImages - 1;

  // To start, assume the result is the last image. If there are no images, the result is null.
  let result = numImages > 0 ? imageDataWithZ[right] : null;

  while (left <= right) {
    const mid = Math.floor((left + right) / 2);
    const midZ = imageDataWithZ[mid].z;

    // If the mid point is greater than or equal to the z value, it is the current candidate for the result.
    if (midZ >= z) {
      result = imageDataWithZ[mid];
      right = mid - 1;
    } else {
      left = mid + 1;
    }
  }

  return result;
}

type SensorDataControlsProps = {
  /**
   * Jotai atom to access the control state for the sensor data visualization.
   */
  controlsAtom: PrimitiveAtom<PrintViewControls>;

  /**
   * Image data with interpolated z values.
   */
  imageDataWithZ: ImageDataWithZ[];
};

type LabelledCheckboxProps = {
  label?: string;
  defaultValue?: boolean;
  onChange?: (checked: boolean) => void;
};

const LabelledCheckbox = ({
  label = "",
  defaultValue = false,
  onChange,
}: LabelledCheckboxProps) => {
  const [checked, setChecked] = useState(defaultValue);
  return (
    <S.LabelledCheckboxContainer>
      <S.Checkbox
        type="checkbox"
        checked={checked}
        onChange={(e) => {
          setChecked(e.target.checked);
          onChange?.(e.target.checked);
        }}
      />
      <S.ControlLabel>{label}</S.ControlLabel>
    </S.LabelledCheckboxContainer>
  );
};

/**
 * Renders controls for the sensor data point cloud.
 */
const SensorDataControls = ({
  controlsAtom,
  imageDataWithZ,
}: SensorDataControlsProps): ReactElement => {
  const [controls, setControls] = useAtom(controlsAtom);

  // Most recently-stored update to the layer slider's z value to used for image lookup
  const [imageLookupZValue, setImageLookupZValue] = useState<number | null>(
    null
  );

  // Timer hook to debounce the image lookup based on the layer slider value changing
  const [zTimerFinished, resetZTimer] = useTimer(200);

  const [image, setImage] = useState<ImageDataWithZ | null>(
    imageDataWithZ.length > 0 ? imageDataWithZ[imageDataWithZ.length - 1] : null
  );

  // Find the image with the closest greater z value to the current z value
  useEffect(() => {
    if (
      zTimerFinished &&
      imageDataWithZ.length > 0 &&
      imageLookupZValue != null
    ) {
      const image = binarySearchImageData(imageDataWithZ, imageLookupZValue);
      if (image) {
        setImage(image);
      }
    }
  }, [imageDataWithZ, imageLookupZValue, zTimerFinished]);

  const { zRange, valueRange, colorRange, pointSize, showNonExtrusionMotion } =
    controls;

  // Reset the sensor data value range slider when `showNonExtrusionMotion` is toggled
  useEffect(() => {
    setControls(
      assocPath(
        ["valueRange", "value"],
        showNonExtrusionMotion ? valueRange.range : valueRange.extrudedRange
      )
    );
  }, [
    showNonExtrusionMotion,
    setControls,
    valueRange.extrudedRange,
    valueRange.range,
  ]);

  // Update the color range slider's range to the value of the value range slider when the latter is changed.
  // Also reset the value of the color slider to be its new full range.
  useEffect(() => {
    setControls(
      pipe(
        assocPath(["colorRange", "range"], valueRange.value),
        assocPath(["colorRange", "value"], valueRange.value)
      )
    );
  }, [setControls, valueRange.value]);

  if (!controls) {
    return <></>;
  }

  const layerSliderRange = showNonExtrusionMotion
    ? zRange.range
    : zRange.extrudedRange;

  const valueSliderRange = showNonExtrusionMotion
    ? valueRange.range
    : valueRange.extrudedRange;

  return (
    <>
      <S.LayerControlContainer>
        <S.ControlLabel>{t`common.layer`}</S.ControlLabel>
        <S.LayerSliderContainer>
          <Slider
            min={layerSliderRange[0]}
            max={layerSliderRange[1]}
            step={0.1}
            defaultValue={zRange.value}
            size="large"
            vertical
            onChange={(values) => {
              setImageLookupZValue((value) => {
                if (value === values[1]) {
                  return value;
                } else {
                  if (zTimerFinished) {
                    resetZTimer();
                  }
                  return values[1];
                }
              });

              setControls(
                assocPath(["zRange", "value"], values as Tuple<number>)
              );
            }}
          />
        </S.LayerSliderContainer>
      </S.LayerControlContainer>
      <S.RightSideContainer>
        <S.PointCloudControlContainer>
          <LabeledSlider
            label={"Color"}
            min={colorRange.range[0]}
            max={colorRange.range[1]}
            step={0.1}
            defaultValue={colorRange.value}
            onChange={(values) => {
              setControls(
                assocPath(["colorRange", "value"], values as Tuple<number>)
              );
            }}
          />
          <LabeledSlider
            label={"Value"}
            min={valueSliderRange[0]}
            max={valueSliderRange[1]}
            step={0.1}
            defaultValue={valueRange.value}
            onChange={(values) => {
              setControls(
                assocPath(["valueRange", "value"], values as Tuple<number>)
              );
            }}
          />
          <LabeledSlider
            label={"Point Size"}
            min={pointSize.range[0]}
            max={pointSize.range[1]}
            step={0.5}
            defaultValue={[pointSize.value]}
            onChange={(value) => {
              setControls(assocPath(["pointSize", "value"], value));
            }}
          />

          <LabelledCheckbox
            label={t`components.controls.analyze.show-motion`}
            defaultValue={showNonExtrusionMotion}
            onChange={(checked) => {
              // Reset the z range to the extruded z range when tool motion is toggled
              setControls((controls) => {
                const zRange = controls.zRange;
                zRange.value = checked ? zRange.range : zRange.extrudedRange;
                return {
                  ...controls,
                  showNonExtrusionMotion: checked,
                  zRange,
                };
              });
            }}
          />
        </S.PointCloudControlContainer>
        {image != null && (
          <S.PhotoWidgetContainer>
            <S.Image src={imageURL(image.id)} thumb={thumbURL(image.id)} />
          </S.PhotoWidgetContainer>
        )}
      </S.RightSideContainer>
    </>
  );
};

type PrintAttemptLoaderProps = {
  /**
   * Uuid of associated project.
   */
  projectId: Uuid;

  /**
   * Uuid of attempt to display.
   */
  attemptId: Uuid;

  /**
   * Attempt sensor datasets to load and display.
   */
  datasets: { motionData: Uuid; toolForce0: Uuid; toolForce1: Uuid };

  /**
   * List of images for the attempt.
   */
  imageDataArray: ImageData[];
};

// Loads the data binaries, constructs point cloud arrays and control layout to render the PrintView component,
// and sets the viewAtom to the PrintView
const PrintAttemptLoader = ({
  projectId,
  attemptId,
  datasets,
  imageDataArray,
}: PrintAttemptLoaderProps) => {
  const [_view, setView] = useAtom(viewAtom);

  const [controlsAtom, setControlsAtom] =
    useState<PrimitiveAtom<PrintViewControls>>();

  const [motionDataProgress, setMotionDataProgress] = useState(0);
  const [toolForce0Progress, setToolForce0Progress] = useState(0);
  const [toolForce1Progress, setToolForce1Progress] = useState(0);
  const [imageDataWithZ, setImageDataWithZ] = useState<ImageDataWithZ[]>([]);

  const {
    data: motionDataBuffer,
    error: motionDataLoadingError,
    isFetched: motionDataFetched,
  } = useAttemptData(
    projectId,
    attemptId,
    datasets.motionData,
    setMotionDataProgress
  );

  const {
    data: toolForce0Buffer,
    error: toolForce0LoadingError,
    isFetched: toolForce0Fetched,
  } = useAttemptData(
    projectId,
    attemptId,
    datasets.toolForce0,
    setToolForce0Progress
  );

  const {
    data: toolForce1Buffer,
    error: toolForce1LoadingError,
    isFetched: toolForce1Fetched,
  } = useAttemptData(
    projectId,
    attemptId,
    datasets.toolForce1,
    setToolForce1Progress
  );

  useEffect(() => {
    if (motionDataFetched) {
      setMotionDataProgress(1);
    }
  }, [motionDataFetched]);

  useEffect(() => {
    if (toolForce0Fetched) {
      setToolForce0Progress(1);
    }
  }, [toolForce0Fetched]);

  useEffect(() => {
    if (toolForce1Fetched) {
      setToolForce1Progress(1);
    }
  }, [toolForce1Fetched]);

  const loadingError =
    !!motionDataLoadingError ||
    !!toolForce0LoadingError ||
    !!toolForce1LoadingError;

  useEffect(() => {
    // clear the controls atom when the data changes so that the controls are reset
    setControlsAtom(undefined);

    if (motionDataBuffer && toolForce0Buffer && toolForce1Buffer) {
      const motionBin = bufferToRecords<MotionData>(motionDataBuffer);
      const toolForce0Bin = bufferToRecords<ForceData>(toolForce0Buffer);
      const toolForce1Bin = bufferToRecords<ForceData>(toolForce1Buffer);

      const dataWithZ = interpolateZValuesForImageTimestamps(
        motionBin,
        imageDataArray
      );
      setImageDataWithZ(dataWithZ);

      const toolForcePointCloudData = constructSensorPointCloud(motionBin, [
        toolForce0Bin,
        toolForce1Bin,
      ]);

      const {
        zRange,
        extrudedZRange,
        sensorValueRange,
        extrudedSensorValueRange,
      } = toolForcePointCloudData.info;

      const cAtom = atom<PrintViewControls>({
        zRange: {
          value: extrudedZRange, // Start with the extruded z range
          extrudedRange: extrudedZRange,
          range: zRange,
        },
        valueRange: {
          value: extrudedSensorValueRange,
          extrudedRange: extrudedSensorValueRange,
          range: sensorValueRange,
        },
        colorRange: {
          value: extrudedSensorValueRange,
          range: sensorValueRange,
        },
        pointSize: {
          range: [0, 10],
          value: 5,
        },
        showNonExtrusionMotion: false,
      });

      setView({
        mode: ViewMode.Print,
        id: attemptId,
        data: toolForcePointCloudData,
        controlsAtom: cAtom,
      });

      setControlsAtom(cAtom);
    }
  }, [
    motionDataBuffer,
    toolForce0Buffer,
    toolForce1Buffer,
    attemptId,
    setView,
    imageDataArray,
  ]);

  if (!controlsAtom) {
    return (
      <ViewerOverlayNotice
        title={t`components.controls.analyze.loading-sensor-data`}
      >
        <S.ProgressWrapper>
          <ProgressBar
            title={t`components.controls.analyze.motion-data`}
            value={motionDataProgress}
          />
          <ProgressBar
            title={t`components.controls.analyze.tool-force ${0}`}
            value={toolForce0Progress}
          />
          <ProgressBar
            title={t`components.controls.analyze.tool-force ${1}`}
            value={toolForce1Progress}
          />
          {loadingError && t`components.controls.loading-error`}
        </S.ProgressWrapper>
      </ViewerOverlayNotice>
    );
  }

  return (
    <SensorDataControls
      controlsAtom={controlsAtom}
      imageDataWithZ={imageDataWithZ}
    />
  );
};

type AttemptDataLoaderProps = {
  /**
   * Project to view.
   */
  project: Project;

  /**
   * Attempt to view.
   */
  attempt: Attempt;

  /**
   * Query range for the attempt.
   */
  queryRange: Tuple<number>;
};

/**
 * Loads the attempt data for the given attempt into the viewer and renders controls for it.
 */
const AttemptDataLoader = ({
  project,
  attempt,
  queryRange,
}: AttemptDataLoaderProps): ReactElement => {
  const { data: manifest } = useAttemptDatasetManifest({
    projectId: project.id,
    attemptId: attempt.id,
  });
  const [_view, setView] = useAtom(viewAtom);

  const { data: imageEvents } = useMachineEvents({
    from: queryRange[0],
    to: queryRange[1],
    kind: ["image"],
    machineId: attempt.machineId,
    limit: 10_000, // enough for a 4-day print at 1 print per 30s
  });

  const imageDataArray: ImageData[] = useMemo(() => {
    if (!imageEvents) return [];
    return imageEvents
      .map((e: ImageEvent) => {
        return { timestamp: e.timestamp, id: e.data.imageId };
      })
      .reverse();
  }, [imageEvents]);

  const [datasets, setDatasets] = useState<{
    motionData: Uuid;
    toolForce0: Uuid;
    toolForce1: Uuid;
  }>();

  useEffect(() => {
    if (!manifest) {
      return;
    }

    const motionData = manifest.find((m) => m.kind === "motionData")?.id;
    const toolForce0 = manifest.find((m) => m.kind === "toolForce0")?.id;
    const toolForce1 = manifest.find((m) => m.kind === "toolForce1")?.id;

    if (
      motionData &&
      toolForce0 &&
      toolForce1 &&
      isViewableAttemptData(manifest)
    ) {
      setDatasets({
        motionData,
        toolForce0,
        toolForce1,
      });
    }
  }, [manifest, project, attempt, setView]);

  if (manifest) {
    if (!datasets) {
      return (
        <ViewerOverlayNotice
          title={t`components.controls.analyze.no-viewable-data-title`}
        >{t`components.controls.analyze.no-viewable-data-message`}</ViewerOverlayNotice>
      );
    } else {
      return (
        <PrintAttemptLoader
          projectId={project.id}
          attemptId={attempt.id}
          datasets={datasets}
          imageDataArray={imageDataArray}
        />
      );
    }
  } else {
    return <></>;
  }
};

type AnalyzeViewerControlPanelProps = {
  /**
   * Project to view.
   */
  project: Project;
  /**
   * Attempts for the project.
   */
  attempts: Attempt[];
};

export const AnalyzeViewerControlPanel = ({
  project,
  attempts,
}: AnalyzeViewerControlPanelProps): ReactElement => {
  const [_match, params] = useRoute(projectRoutes.analyze);

  const attempt = useMemo(
    () => attempts.find((a) => a.id === params?.attemptId),
    [attempts, params?.attemptId]
  );

  const queryRange = useMemo(() => {
    if (attempt) {
      return [
        new Date(attempt.startedAt).getTime() * 1000,
        attempt.endedAt
          ? new Date(attempt.endedAt).getTime() * 1000
          : Date.now() * 1000,
      ] as Tuple<number>;
    }
    return null;
  }, [attempt]);

  return attempt && queryRange ? (
    <AttemptDataLoader
      project={project}
      attempt={attempt}
      queryRange={queryRange}
    />
  ) : (
    <ViewerOverlayNotice
      title={t`components.controls.analyze.no-attempts-title`}
    >{t`components.controls.analyze.no-attempts-message`}</ViewerOverlayNotice>
  );
};
