import styled, { css } from "styled-components";
import { transparentize } from "polished";

import { Icon as _Icon } from "_/components/icon";

const disabledStyle = css`
  background-color: ${(p) => p.theme.input.disabled.background};
  color: ${(p) => p.theme.input.disabled.text};
  border: 1px solid ${(p) => transparentize(0.5, p.theme.input.disabled.text)};

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px ${(p) => p.theme.input.disabled.background} inset;
    -webkit-text-fill-color: ${(p) => p.theme.input.disabled.text};
  }
`;

const focusedStyle = css`
  background-color: ${(p) => p.theme.input.focused.background};
  color: ${(p) => p.theme.input.focused.text};
  border: 1px solid ${(p) => p.theme.input.focused.border};

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px ${(p) => p.theme.input.focused.background} inset;
    -webkit-text-fill-color: ${(p) => p.theme.input.focused.text};
  }
`;

const invalidStyle = css`
  background-color: ${(p) => p.theme.input.invalid.background};
  color: ${(p) => p.theme.input.invalid.text};
  border: 1px solid ${(p) => p.theme.input.invalid.border};

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px ${(p) => p.theme.input.invalid.background} inset;
    -webkit-text-fill-color: ${(p) => p.theme.input.invalid.text};
  }
`;

export const Input = styled.input`
  background-color: transparent;
  border: none;
  color: inherit;
  width: 100%;
  padding: 0;
  font-size: inherit;
  color-scheme: ${(p) => p.theme.input.colorScheme};
`;

export const ClickableIcon = styled(_Icon)`
  cursor: pointer;
  color: ${(p) => p.theme.text.muted};

  &:hover {
    color: ${(p) => p.theme.text.highlight};
  }
`;

export const Wrapper = styled.div<{
  $invalid: boolean;
  $border: boolean;
  $disabled?: boolean;
  $focused: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  width: 100%;

  margin: 0;
  padding: 8px 16px 6px;

  background-color: ${(p) => p.theme.input.default.background};
  font-size: 15px;
  color: ${(p) => p.theme.input.default.text};

  border-radius: 2px;
  border: 1px solid
    ${(p) => (p.$border ? p.theme.input.default.border : "transparent")};

  transition: border 0.1s ease;

  // "Hack" to override autofill styles for chrome.
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    box-shadow: 0 0 0 1000px ${(p) => p.theme.input.default.background} inset;
    -webkit-text-fill-color: ${(p) => p.theme.input.default.text};
  }

  ${(p) => p.$focused && focusedStyle}
  ${(p) => p.$invalid && invalidStyle}
  ${(p) => p.$disabled && disabledStyle}

  &:hover {
    ${(p) => {
      const showHover = !(p.$disabled || p.$invalid || p.$focused);
      return showHover
        ? `border: 1px solid ${p.theme.input.hover.border};`
        : null;
    }};
  }
`;

export const TextFieldWrapper = styled.div`
  margin-bottom: 12px;
`;

export const ScoreWrapper = styled.div`
  display: flex;
  gap: 2px;
  align-items: center;

  padding: 12px;
  padding-right: 0;

  height: 32px;
  border-radius: 4px;

  background-color: ${(p) => p.theme.color.palette.orcaBlack};
`;

export const Block = styled.div<{ $color: string }>`
  width: 44px;
  height: 12px;

  background-color: ${(p) => p.$color};
  border-radius: 1px;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const ScoreLabel = styled.span<{ $color: string }>`
  width: 90px;
  text-align: center;
  color: ${(p) => p.$color};
  font-size: 12px;
`;

export const ValidationDisplayWrapper = styled.div`
  font-size: 12px;

  margin: 24px 12px;
  padding: 12px 20px;

  background-color: ${(p) => p.theme.color.palette.smokeGrey};

  width: 320px;

  border-radius: 4px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  &:after {
    content: "";
    position: absolute;
    pointer-events: none;

    right: -10px;
    top: 43px;
    transform: translateY(-50%) rotate(90deg);

    border: 12px solid transparent;
    border-bottom-color: ${(p) => p.theme.color.palette.smokeGrey};
  }
`;

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  padding: 8px;

  border-radius: 4px;

  vertical-align: middle;

  border: 1px solid ${(p) => p.theme.color.palette.pineappleYellow};
`;

export const SuggestionsContainer = styled.div`
  padding-right: 4px;
`;

export const SuggestionList = styled.ul`
  padding-left: 1.5rem;
`;

export const WarningIcon = styled(_Icon)`
  font-size: 20px;
  color: ${(p) => p.theme.color.palette.pineappleYellow};
  vertical-align: middle;
`;
