import styled from "styled-components";
import { transparentize } from "polished";
import { Root, Track, Range, Thumb } from "@radix-ui/react-slider";

export type SliderSize = "small" | "large";

const thumbSize = (sliderSize: SliderSize) =>
  sliderSize === "small" ? 14 : 16;

const trackThickness = (sliderSize: SliderSize) =>
  sliderSize === "small" ? 4 : 5;

export const SliderRoot = styled(Root)<{ $size: SliderSize }>`
  position: relative;
  display: flex;
  align-items: center;
  user-select: none;
  touch-action: none;
  width: 100%;
  height: ${(p) => thumbSize(p.$size)}px;
  cursor: pointer;

  &[data-orientation="vertical"] {
    height: 100%;
    width: ${(p) => thumbSize(p.$size)}px;
    flex-direction: column;
  }
`;

export const SliderTrack = styled(Track)<{ $size: SliderSize }>`
  background-color: ${(p) =>
    transparentize(0.5, p.theme.color.palette.electricBlue)};
  position: relative;
  flex-grow: 1;
  height: ${(p) => trackThickness(p.$size)}px;
  margin: 0 ${(p) => thumbSize(p.$size) / 2}px;

  &[data-orientation="vertical"] {
    margin: ${(p) => thumbSize(p.$size) / 2}px 0;
    height: 100%;
    width: ${(p) => trackThickness(p.$size)}px;
  }
`;

export const SliderRange = styled(Range)`
  position: absolute;
  background-color: ${(p) => p.theme.color.palette.electricBlue};
  height: 100%;

  &[data-orientation="vertical"] {
    height: unset;
    width: 100%;
    flex-direction: column;
  }
`;

export const SliderThumb = styled(Thumb)<{ $size: SliderSize }>`
  display: block;
  width: ${(p) => thumbSize(p.$size)}px;
  height: ${(p) => thumbSize(p.$size)}px;
  background-color: ${(p) => p.theme.color.palette.electricBlue};
  border-radius: 2px;

  &:hover {
    background-color: ${(p) => p.theme.color.palette.electricBlue};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${(p) => p.theme.color.palette.twilightBlue};
  }
`;
