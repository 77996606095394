import React, { Fragment, useEffect, useState } from "react";

import { Slider } from "_/components/slider";

import * as S from "./styled";

type LabelledSliderProps = {
  /**
   * Label to display above the slider.
   */
  label: string;

  /**
   * Minimum value of the slider.
   */
  min: number;

  /**
   * Maximum value of the slider.
   */
  max: number;

  /**
   * Step size of the slider.
   */
  step: number;

  /**
   * Default value of the slider.
   */
  defaultValue: number[];

  /**
   * Called when the slider value changes.
   */
  onChange: (value: number[]) => void;
};

export const LabeledSlider = ({
  label,
  min,
  max,
  step,
  defaultValue,
  onChange,
}: LabelledSliderProps) => {
  const [val, setVal] = useState(defaultValue);

  useEffect(() => {
    setVal(defaultValue);
  }, [defaultValue]);

  const decimalPlaces = step.toString().split(".")[1]?.length ?? 0;
  const toValueLabel = (v: number) => {
    return (Math.round(v / step) * step).toFixed(decimalPlaces);
  };

  return (
    <S.LabelledSlider>
      <S.ControlLabel>
        <span>{label}</span>
        <S.SliderValueLabel>
          {val.map((v, i) => (
            <Fragment key={i}>
              {i > 0 ? <>&nbsp;&mdash;&nbsp;</> : null}
              {toValueLabel(v)}
            </Fragment>
          ))}
        </S.SliderValueLabel>
      </S.ControlLabel>
      <Slider
        min={min}
        max={max}
        step={step}
        defaultValue={defaultValue}
        onChange={(value) => {
          setVal(value);
          onChange(value);
        }}
      />
    </S.LabelledSlider>
  );
};
