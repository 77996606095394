import React from "react";
import dayjs from "dayjs";
import {
  UNKNOWN_VALUE_STRING,
  NON_BREAKING_DASH,
  NON_BREAKING_SPACE,
} from "_/utils/consts";

interface DateTimeProps {
  /** Value to format. If a number, must be msecs since epoch. */
  value: Date | string | number | undefined | null;
  /** What to show if the value is undefined or null */
  defaultValue?: string;
  /** Whether to allow wrapping on the space between date and time */
  nowrap?: boolean;
  /** How much time to show */
  precision?: "minute" | "second" | "millisecond";
  /** Delimiter to use to separate year, month, and day. Defaults to
   * non-breaking hyphen “‑” (U+2011) */
  dateDelimiter?: string;
}

/**
 * Display a date and time in ISO 8601 format (YYYY-MM-DD, e.g. 2024-01-24
 * 14:52) that's the standard format in Basis. Use the browser's time zone.
 * If rendering a date and time, use the <DateTime> component.
 */
export const dateTimeString = ({
  value,
  defaultValue,
  nowrap,
  precision,
  dateDelimiter = NON_BREAKING_DASH,
}: DateTimeProps) => {
  if (value == null) {
    return defaultValue ?? UNKNOWN_VALUE_STRING;
  }
  const space = nowrap ? NON_BREAKING_SPACE : " ";
  const onlyProps = { value, defaultValue, precision, dateDelimiter };
  return `${dateOnlyString(onlyProps)}${space}${timeOnlyString(onlyProps)}`;
};

/**
 * Display a date and time in ISO 8601 format (YYYY-MM-DD, e.g. 2024-01-24
 * 14:52) that's the standard format in Basis. Use the browser's time zone.
 */
export const DateTime = (props: DateTimeProps) => <>{dateTimeString(props)}</>;

type DateOnlyProps = Omit<DateTimeProps, "nowrap">;

/**
 * Display a date (only) in ISO 8601 format (YYYY-MM-DD, e.g. 2024-01-24 14:52)
 * that's the standard format in Basis. Use the browser's time zone. If
 * rendering a date, use the <DateOnly> component.
 */
export const dateOnlyString = ({
  value,
  defaultValue,
  dateDelimiter = NON_BREAKING_DASH,
}: DateOnlyProps) => {
  if (value == null) {
    return defaultValue ?? UNKNOWN_VALUE_STRING;
  }
  return dayjs(new Date(value)).format(
    `YYYY${dateDelimiter}MM${dateDelimiter}DD`
  );
};

/**
 * Display a date (only) in ISO 8601 format (YYYY-MM-DD, e.g. 2024-01-24 14:52)
 * that's the standard format in Basis. Use the browser's time zone.
 */
export const DateOnly = (props: DateOnlyProps) => <>{dateOnlyString(props)}</>;

type TimeOnlyProps = Omit<DateTimeProps, "nowrap">;

/**
 * Display a time (only) in 24-hour time format (HH:mm, e.g. 14:52) which is the
 * standard format in Basis. Use the browser's time zone. If rendering a time,
 * use the <TimeOnly> component.
 */
export const timeOnlyString = ({
  value,
  defaultValue,
  precision = "minute",
}: TimeOnlyProps) => {
  if (value == null) {
    return defaultValue ?? UNKNOWN_VALUE_STRING;
  }
  const dj = dayjs(new Date(value));
  switch (precision) {
    case "millisecond":
      return dj.format(`HH:mm:ss.SSS`);
    case "second":
      return dj.format(`HH:mm:ss`);
    case "minute":
      return dj.format(`HH:mm`);
    default:
      throw new Error(`Invalid precision: ${precision}`);
  }
};

/**
 * Display a time (only) in 24-hour time format (HH:mm, e.g. 14:52) which is the
 * standard format in Basis. Use the browser's time zone.
 */
export const TimeOnly = (props: TimeOnlyProps) => <>{timeOnlyString(props)}</>;

export const isSameDayInBrowserTimeZone = (d1: Date, d2: Date) =>
  dayjs(d1).isSame(d2, "day");

interface DateTimeRangeProps extends Omit<DateTimeProps, "value"> {
  /** Value to format. If a number, must be msecs since epoch. */
  start: Date | string | number | undefined | null;
  /** Value to format. If a number, must be msecs since epoch. */
  end: Date | string | number | undefined | null;
}

/**
 * Display a date/time range in ISO 8601 format. If start and end dates are the
 * same, then omit the second date. Otherwise show both dates. It's the caller's
 * responsibility to ensure that `start` is before `end`.
 *
 * Examples: "2024-01-24 14:52 - 15:52", "2024-01-24 14:52 - 2024-01-24 15:23"
 */
export const dateTimeRangeString = ({
  start,
  end,
  defaultValue,
  nowrap,
}: DateTimeRangeProps) => {
  if (start == null) {
    return defaultValue ?? UNKNOWN_VALUE_STRING;
  }
  const space = nowrap ? NON_BREAKING_SPACE : " ";
  const startString = dateTimeString({
    value: start,
    defaultValue,
    nowrap,
  });
  if (end == null) return `${startString}${space}${NON_BREAKING_DASH}`;
  const endString = isSameDayInBrowserTimeZone(new Date(start), new Date(end))
    ? timeOnlyString({ value: end })
    : dateTimeString({ value: end, nowrap: true });
  return `${startString}${space}${NON_BREAKING_DASH}${space}${endString}`;
};

/**
 * Display a date/time range in ISO 8601 format. If start and end dates are the
 * same, then omit the second date. Otherwise show both dates. It's the caller's
 * responsibility to ensure that `start` is before `end`.
 *
 * Examples: "2024-01-24 14:52 - 15:52", "2024-01-24 14:52 - 2024-01-24 15:23"
 */
export const DateTimeRange = (props: DateTimeRangeProps) => (
  <>{dateTimeRangeString(props)}</>
);
