import React, { ReactElement, ReactNode, useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { t } from "@lingui/macro";
import { pick } from "ramda";
import { Row } from "@tanstack/react-table";

import { UserAvatar } from "_/components/avatar";
import { Button, ButtonGroup } from "_/components/button";
import { DateTime, dateOnlyString } from "_/components/date-time";
import { Machine } from "_/data/machines";
import { AdminMachineListing } from "_/components/machine-listing";
import { SubmitCancelButtons, useModal } from "_/components/modal";
import { Link, Redirect, Route, Switch, useRoute } from "_/components/router";
import { ColumnDef, Table } from "_/components/table";
import { TabBar, TabForRoute } from "_/components/tab-bar";
import { TextField, FormTextField } from "_/components/text-field";
import { Select, Option } from "_/components/select";
import { InviteUserForm, UserDetailForm } from "_/components/settings-view";
import { UuidTooltip } from "_/components/uuid-tooltip";
import { MenuItem, OverlayMenu } from "_/components/overlay-menu";
import { Icon } from "_/components/icon";
import { wrapLabelHeader } from "_/components/form-field";
import { RadioButton, RadioGroup } from "_/components/radio";
import { useToasts } from "_/components/toasts";
import { Roles } from "_/components/rbac";
import { PermissionTest, testPermissions } from "_/components/permission-test";

import {
  useMaterials,
  Material,
  Manufacturer,
  useManufacturers,
  useCreateMaterial,
  useUpdateMaterial,
  NewMaterial,
  MaterialUpdate,
  NewManufacturer,
  ManufacturerUpdate,
  useCreateManufacturer,
  useUpdateManufacturer,
} from "_/data/materials";
import {
  Org,
  useCheckCurrentOrg,
  useOrg,
  useOrgs,
  useCreateOrg,
  useUpdateOrg,
  useCurrentOrg,
} from "_/data/orgs";
import {
  OrganizationUser,
  User,
  useUsersByOrg,
  useReviewUserOrgInvite,
  useIsAonUser,
} from "_/data/users";
import {
  permissions,
  ROOT_DOMAIN,
  useAssignableRoles,
  Permission,
  useCurrentUserPermissions,
} from "_/data/rbac";

import { getLanguageName } from "_/i18n";
import { adminRoutes, adminUrls, settingsUrls } from "_/routes";
import { Uuid } from "_/types";

import * as S from "./styled";

type TabInfo = {
  name: string;
  route: string;
  url: string;
  title?: Record<string, ReactNode>;
  Component: () => ReactElement;
  requiredPermissions?: Permission | Permission[] | Permission[][];
};

const OrgName = () => {
  const [_, params] = useRoute(adminRoutes.organization);
  const { data: org } = useOrg(params?.id);
  const name = org?.name;
  return t`components.admin-view.admin-title ${name}`;
};

const TopLevelTabs = ({
  tabs,
  showTabBar,
}: {
  tabs: TabInfo[];
  showTabBar: boolean;
}): ReactElement => {
  return (
    <S.Header>
      {showTabBar && (
        <S.Nav>
          <TabBar>
            {tabs.map(({ name, route, url }) => (
              <TabForRoute key={route} route={route} url={url}>
                {name}
              </TabForRoute>
            ))}
          </TabBar>
        </S.Nav>
      )}
      {tabs.map(({ name, route, title }) => (
        <Route path={route} key={route}>
          <h1>
            {title
              ? Object.keys(title).map((key) => (
                  <Route path={key} key={key}>
                    {title[key]}
                  </Route>
                ))
              : name}
          </h1>
        </Route>
      ))}
    </S.Header>
  );
};

const FormField = ({
  children,
  label,
}: {
  children: ReactNode;
  label?: string;
}) => {
  const content = <S.FormInputContainer>{children}</S.FormInputContainer>;

  return (
    <S.FormField>
      {label ? wrapLabelHeader(content, label) : content}
    </S.FormField>
  );
};

const OrgEditForm = ({ org, onClose }: { org: Org; onClose: () => void }) => {
  const pickFields = pick(["name"]);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: pickFields(org),
  });

  const updateOrg = useUpdateOrg();

  const onSubmit = handleSubmit(async (data) => {
    updateOrg.mutateAsync({ id: org.id, update: data });
    onClose?.();
  });

  return (
    <form onSubmit={onSubmit}>
      <FormField label={t`common.name`}>
        <Controller
          control={control}
          name="name"
          rules={{ required: t`components.admin-view.name-required` }}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              placeholder={t`components.projects-view.org-name`}
              invalid={fieldState.invalid}
              hint={fieldState.error?.message}
            />
          )}
        />
      </FormField>
      <SubmitCancelButtons onCancel={onClose} disableSubmit={!isDirty} />
    </form>
  );
};

const OrgCreateForm = ({ onClose }: { onClose: () => void }) => {
  const {
    control,
    handleSubmit,
    formState: { dirtyFields },
  } = useForm({
    defaultValues: {
      name: "",
      makeCurrentUserMember: false,
    },
  });

  const createOrg = useCreateOrg();

  const onSubmit = handleSubmit(async (data) => {
    createOrg.mutateAsync(data);
    onClose?.();
  });

  return (
    <form onSubmit={onSubmit}>
      <FormTextField
        control={control}
        name="name"
        rules={{ required: t`components.admin-view.name-required` }}
        placeholder={t`components.projects-view.org-name`}
      />
      <Controller
        control={control}
        name="makeCurrentUserMember"
        render={({ field }) => (
          <S.LabelledCheckboxContainer>
            <S.Checkbox
              type="checkbox"
              checked={field.value}
              onChange={(e) => field.onChange(e.target.checked)}
            />
            <S.ControlLabel>{t`components.admin-view.make-current-user-first-member`}</S.ControlLabel>
          </S.LabelledCheckboxContainer>
        )}
      />
      <SubmitCancelButtons
        submitButtonLabel={t`common.create`}
        onCancel={onClose}
        disableSubmit={!dirtyFields.name}
      />
    </form>
  );
};

const Organizations = () => {
  const { data: orgs } = useOrgs();
  const createOrgModal = useModal<void>();
  const editOrgModal = useModal<Org>();

  // Filter out organizations that the user does not have permission to view
  const readOrgs = orgs.filter((org) =>
    org.permissions.includes(permissions.organizations.read)
  );

  const columns: ColumnDef<Org>[] = [
    {
      id: "name",
      header: t`components.settings-view.org-name`,
      accessorKey: "name",
      cell: ({ row: { original: rowData } }) => rowData.name,
    },
    {
      id: "id",
      header: t`common.id`,
      accessorKey: "id",
      cell: ({ row: { original: rowData } }) => (
        <UuidTooltip uuid={rowData.id} />
      ),
    },
    {
      id: "actions",
      header: t`common.actions`,
      cell: ({ row: { original: rowData } }) => (
        <ButtonGroup>
          <Link to={adminUrls.organization(rowData.id)}>
            <Button size="small">{t`common.details`}</Button>
          </Link>
          <PermissionTest
            domainId={rowData.domainId}
            requiredPermissions={"organizations/write"}
            render={({ allowed }) => (
              <Button
                size="small"
                kind="secondary"
                onClick={() => editOrgModal.openModal(rowData)}
                disabled={!allowed}
              >
                {t`common.edit`}
              </Button>
            )}
          />
        </ButtonGroup>
      ),
    },
  ];

  return (
    <S.AdminContent>
      <S.AdminContentSection>
        <S.AdminContentSectionTitleContainer>
          <h2></h2>
          <PermissionTest
            domainId={ROOT_DOMAIN}
            requiredPermissions={"organizations/create"}
            render={({ allowed }) => (
              <Button
                onClick={() => createOrgModal.openModal(undefined)}
                disabled={!allowed}
              >{t`components.admin-view.create-org`}</Button>
            )}
          />
        </S.AdminContentSectionTitleContainer>
        <Table
          columns={columns}
          data={readOrgs}
          initialState={{ sorting: [{ id: "name", desc: false }] }}
        />
      </S.AdminContentSection>
      {createOrgModal.isModalOpen && (
        <createOrgModal.Modal title={t`components.admin-view.create-org`}>
          <OrgCreateForm onClose={createOrgModal.closeModal} />
        </createOrgModal.Modal>
      )}
      {editOrgModal.modalData && (
        <editOrgModal.Modal title={t`components.admin-view.edit-org`}>
          <OrgEditForm
            org={editOrgModal.modalData}
            onClose={editOrgModal.closeModal}
          />
        </editOrgModal.Modal>
      )}
    </S.AdminContent>
  );
};

interface UsersListProps {
  org: Org;
}

const UsersList = ({ org }: UsersListProps) => {
  const { data: members } = useUsersByOrg(org.id);
  const { data: orgs } = useOrgs();
  const { closeModal, openModal, modalData, Modal } = useModal<User>();
  const reviewUserOrgInvite = useReviewUserOrgInvite();

  const { data: assignableRoles } = useAssignableRoles(org.domainId);

  const { data: permissions } = useCurrentUserPermissions(org.domainId);

  const canEdit = testPermissions("users/write", permissions);

  const canReviewInvite = testPermissions(
    "organizations/reviewMember",
    permissions
  );

  function submitReview(userId: Uuid, orgId: Uuid, approved: boolean) {
    reviewUserOrgInvite.mutateAsync({
      userId,
      orgId,
      approved,
    });
  }

  // Filter out users whose invite has been denied.
  const filteredMembers = members?.filter(
    (member) => !member.reviewedBy || member.approved
  );

  if (!filteredMembers?.length || !orgs?.length) {
    return null;
  }

  const pendingString = t`common.pending`;

  const columns: ColumnDef<OrganizationUser>[] = [
    {
      id: "avatar_id",
      header: "",
      cell: ({ row: { original: rowData } }) => {
        const user = rowData.user;
        if (!user) return null;
        return (
          <div title={user.name}>
            <UserAvatar resource={user} size="small" />
          </div>
        );
      },
    },
    {
      id: "name",
      header: t`common.name`,
      accessorKey: "user.name",
      cell: ({ row: { original: rowData } }) =>
        rowData.user.name + (rowData.approved ? "" : ` (${pendingString})`),
    },
    {
      id: "email",
      header: t`common.email`,
      accessorKey: "user.email",
      cell: ({ row: { original: rowData } }) => rowData.user.email,
    },
    {
      id: "phone",
      header: t`common.phone`,
      accessorKey: "user.phone",
      cell: ({ row: { original: rowData } }) => rowData.user.phone,
    },
    {
      id: "language",
      header: t`common.language`,
      accessorKey: "user.language",
      cell: ({ row: { original: rowData } }) => {
        return getLanguageName(rowData.user.language);
      },
    },
    {
      id: "verified",
      header: t`common.verified`,
      accessorKey: "user.verified",
      cell: ({ cell }) => cell.getValue() && <S.Checkmark>✓</S.Checkmark>,
    },
    {
      id: "roles",
      header: t`common.roles`,
      cell: ({ row: { original: rowData } }) => (
        <Roles user={rowData.user} org={org} showGlobalRoles={true} />
      ),
    },
    {
      id: "last_authenticated",
      header: t`common.last-authenticated`,
      accessorKey: "user.lastAuthenticated",
      cell: ({ row: { original: rowData } }) => (
        <DateTime value={rowData.user.lastAuthenticated} />
      ),
    },
    {
      id: "id",
      header: t`common.id`,
      accessorKey: "user.id",
      cell: ({ row: { original: rowData } }) => (
        <UuidTooltip uuid={rowData.user.id} />
      ),
    },
    {
      id: "actions",
      header: t`common.actions`,
      cell: ({ row: { original: rowData } }) => (
        <ButtonGroup>
          {!rowData.reviewedBy && canReviewInvite && (
            <>
              <Button
                size="small"
                kind="primary"
                onClick={() => submitReview(rowData.user.id, org.id, true)}
              >
                {t`common.approve`}
              </Button>
              <Button
                size="small"
                kind="danger"
                onClick={() => submitReview(rowData.user.id, org.id, false)}
              >
                {t`common.deny`}
              </Button>
            </>
          )}
          <Button
            size="small"
            kind="secondary"
            onClick={() => openModal(rowData.user)}
            disabled={!canEdit}
          >
            {t`common.edit`}
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={filteredMembers}
        initialState={{ sorting: [{ id: "name", desc: false }] }}
      />
      <Modal title={t`components.admin-view.edit-user`}>
        {modalData && assignableRoles && (
          <UserDetailForm
            user={modalData}
            adminData={{
              org,
              assignableRoles,
            }}
            onClose={closeModal}
          />
        )}
      </Modal>
    </>
  );
};

const AdminSearch = () => {
  return (
    <S.ComingSoonNote>
      <p>
        <b>Coming Soon</b>
      </p>
      <p>
        This page will make it easy to find a specific Basis user, a Hylo
        machine, an org, and probably other stuff too.
      </p>
      <p>
        To actually do things with users, machines, etc. this page wil link you
        to the correct page, probably the Organization Detail page (for users or
        normal machines) or the Factory page for machines that are currently
        being manufactured.
      </p>
    </S.ComingSoonNote>
  );
};

const MachineRenameForm = ({
  machine,
  onClose,
}: {
  machine: Machine;
  onClose: () => void;
}) => {
  const defaultValues = {
    name: machine.name,
  };
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues });

  const onSubmit = handleSubmit((data) => {
    console.log(`rename machine ${machine.id}`, data);
    onClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <FormField label={t`common.serial-number`}>
        {machine.hardware.serial}
      </FormField>
      <FormField label={t`common.machine-manufacture-date`}>
        {dateOnlyString({ value: machine.hardware.manufactureDate })}
      </FormField>
      <FormField label={t`common.name`}>
        <Controller
          control={control}
          name="name"
          rules={{ required: t`components.admin-view.name-required` }}
          render={({ field, fieldState }) => (
            // TODO: validation for name format
            <TextField
              {...field}
              placeholder={t`common.name`}
              invalid={fieldState.invalid}
              hint={fieldState.error?.message}
            />
          )}
        />
      </FormField>
      <SubmitCancelButtons onCancel={onClose} disableSubmit={!isDirty} />
    </form>
  );
};

const MachineDetailForm = ({
  machine,
  onClose,
}: {
  machine?: Machine;
  onClose: () => void;
}) => {
  const defaultValues = machine
    ? {
        ...pick(["name"], machine),
        ...pick(["serial", "manufactureDate"], machine.hardware),
      }
    : {
        name: "",
        serial: "",
        manufactureDate: dateOnlyString({
          value: new Date(),
          dateDelimiter: "-",
        }),
      };
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues });

  const onSubmit = handleSubmit((data) => {
    if (machine) {
      console.log(`update machine ${machine.id}`, data);
    } else {
      const HYLO_GENERATION = 3;
      console.log("create machine", {
        ...data,
        // TODO: change back-end to fill in generation and model there.
        generation: HYLO_GENERATION,
        model: "hylo",
      });
    }
    onClose();
  });

  return (
    <form onSubmit={onSubmit}>
      <S.ComingSoonNote>
        <p>
          <b>Coming Soon</b>
        </p>
        <p>{"Submitting this form won't do anything yet."}</p>
      </S.ComingSoonNote>
      <FormField label={t`common.serial-number`}>
        <Controller
          control={control}
          name="serial"
          rules={{ required: t`components.admin-view.serial-number-required` }}
          render={({ field, fieldState }) => (
            // TODO: validation for serial number format
            <TextField
              {...field}
              placeholder={t`common.serial-number`}
              invalid={fieldState.invalid}
              hint={fieldState.error?.message}
            />
          )}
        />
      </FormField>
      <FormField label={t`common.name`}>
        <Controller
          control={control}
          name="name"
          rules={{ required: t`components.admin-view.name-required` }}
          render={({ field, fieldState }) => (
            // TODO: fill in a default name based on serial number
            // TODO: validation for name format
            <TextField
              {...field}
              placeholder={t`common.name`}
              invalid={fieldState.invalid}
              hint={fieldState.error?.message}
            />
          )}
        />
      </FormField>
      <FormField label={t`common.machine-manufacture-date`}>
        <Controller
          control={control}
          name="manufactureDate"
          rules={{
            required: t`components.admin-view.machine-manufacture-date-required`,
          }}
          render={({ field, fieldState }) => (
            // TODO: validation for serial number format
            <TextField
              type="date"
              {...field}
              onChange={(e: unknown) => {
                field.onChange(e);
                console.log("change", e);
              }}
              placeholder={t`common.machine-manufacture-date`}
              invalid={fieldState.invalid}
              hint={fieldState.error?.message}
            />
          )}
        />
      </FormField>
      <SubmitCancelButtons onCancel={onClose} disableSubmit={!isDirty} />
    </form>
  );
};

const MachineTransferForm = ({
  machine,
  onClose,
}: {
  machine: Machine;
  onClose: () => void;
}) => {
  const { data: orgs } = useOrgs();
  const defaultValues = { org: undefined as Uuid | undefined };
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues });

  const onSubmit = handleSubmit((data) => {
    console.log(`transfer machine ${machine.id}`, data);
    onClose();
  });

  if (!orgs?.length) return null;

  return (
    <form onSubmit={onSubmit}>
      <FormField label={t`common.serial-number`}>
        {machine.hardware.serial}
      </FormField>
      <FormField label={t`common.name`}>{machine.name}</FormField>
      <FormField label={t`common.machine-manufacture-date`}>
        {dateOnlyString({ value: machine.hardware.manufactureDate })}
      </FormField>
      <FormField label={t`common.admin-view.transfer-destination-org`}>
        <Controller
          control={control}
          name="org"
          render={({ field }) => (
            <S.SelectWrapper>
              <Select
                {...field}
                options={orgs.map((o) => ({
                  label: o.name,
                  value: o.id,
                }))}
              />
            </S.SelectWrapper>
          )}
        />
      </FormField>
      <SubmitCancelButtons
        submitButtonLabel={t`components.admin-view.transfer-button-label`}
        onCancel={onClose}
        disableSubmit={!isDirty}
      />
    </form>
  );
};

type PigmentValue = "natural" | "pigmented";

type SimulationCapability = "notSimulatable" | "simulatable" | "optimizable";

// Type for the default / current value of `MaterialEditForm`
type MaterialValue = {
  name: string;
  color: string;
  pigmented: PigmentValue;
  manufacturerId?: Uuid;
  capabilities?: SimulationCapability;
};

function getSimulationCapabilityLiteral(
  capabilities: Material["capabilities"]
): SimulationCapability {
  const { simulation, optimization } = capabilities;

  return simulation
    ? optimization
      ? "optimizable"
      : "simulatable"
    : "notSimulatable";
}

function getSimulationCapabilityObject(
  capability: SimulationCapability
): Material["capabilities"] {
  switch (capability) {
    case "notSimulatable":
      return { simulation: false, optimization: false };
    case "simulatable":
      return { simulation: true, optimization: false };
    case "optimizable":
      return { simulation: true, optimization: true };
  }
}

const MaterialEditForm = ({
  material,
  onClose,
}: {
  material?: Material;
  onClose: () => void;
}) => {
  // If the `material` prop is provided, use the form for updating that material record.
  // Otherwise, create a new material.

  const defaultValues: MaterialValue = material
    ? {
        ...material,
        pigmented: material.pigmented ? "pigmented" : "natural",
        manufacturerId: material.manufacturer.id,
        capabilities:
          material && getSimulationCapabilityLiteral(material.capabilities),
      }
    : {
        name: "",
        color: "#000000",
        pigmented: "natural",
        manufacturerId: undefined,
        capabilities: undefined,
      };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues,
  });

  const createMaterial = useCreateMaterial();

  const updateMaterial = useUpdateMaterial();

  const [_, addToast] = useToasts();

  const onSubmit = handleSubmit((data) => {
    const { name, color, pigmented, capabilities, manufacturerId } = data;

    if (material) {
      const update: Omit<MaterialUpdate, "archived"> = {
        name,
        color: color.toUpperCase(),
        pigmented: pigmented === "pigmented",
        capabilities:
          capabilities && getSimulationCapabilityObject(capabilities),
        manufacturerId,
      };

      updateMaterial.mutateAsync({
        id: material?.id,
        update,
        onError: (code?: number) => {
          const title =
            code === 403
              ? t`components.admin-view.action-permission-denied`
              : t`components.admin-view.material-update-failed`;

          addToast({
            title,
            kind: "warning",
            timeout: 5_000,
          });
        },
      });
    } else if (name && color && pigmented && manufacturerId && capabilities) {
      const newMaterial: NewMaterial = {
        name,
        color: color.toUpperCase(),
        pigmented: pigmented === "pigmented",
        manufacturerId,
        capabilities: getSimulationCapabilityObject(capabilities),
        domainId: ROOT_DOMAIN,
      };

      createMaterial.mutateAsync({
        newMaterial,
        onError: (code?: number) => {
          const title =
            code === 403
              ? t`components.admin-view.action-permission-denied`
              : t`components.admin-view.material-creation-failed`;

          addToast({
            title,
            kind: "warning",
            timeout: 5_000,
          });
        },
      });
    }
    onClose();
  });

  const { data: manufacturers } = useManufacturers();

  const colorPickerRef = useRef<HTMLInputElement>(null);

  const pigmentOptions: Option<PigmentValue>[] = [
    { label: t`components.admin-view.natural`, value: "natural" },
    { label: t`components.admin-view.pigmented`, value: "pigmented" },
  ];

  const manufacturerOptions: Option<Uuid>[] = useMemo(
    () => manufacturers?.map((mf) => ({ label: mf.name, value: mf.id })) ?? [],
    [manufacturers]
  );

  const simulationOptions: Option<SimulationCapability>[] = [
    {
      label: t`common.not-simulatable`,
      value: "notSimulatable",
    },
    {
      label: t`common.simulatable`,
      value: "simulatable",
    },
    {
      label: t`common.optimizable`,
      value: "optimizable",
    },
  ];

  return (
    <form onSubmit={onSubmit}>
      <FormTextField
        control={control}
        name="name"
        label={t`common.name`}
        rules={{ required: t`components.admin-view.name-required` }}
        placeholder={t`components.admin-view.material-name`}
      />
      <FormField label={t`common.color`}>
        <Controller
          control={control}
          name="color"
          render={({ field: { value, onChange, onBlur } }) => (
            <S.MaterialColorFormFieldContainer
              onClick={() => {
                colorPickerRef.current?.click();
              }}
            >
              <S.MaterialColorContainer>
                <S.MaterialColorBox $color={value} />
                {value}
                <input
                  type="color"
                  value={value}
                  ref={colorPickerRef}
                  onChange={onChange}
                  onBlur={onBlur}
                />
              </S.MaterialColorContainer>
            </S.MaterialColorFormFieldContainer>
          )}
        />
      </FormField>
      <Controller
        control={control}
        name="pigmented"
        render={({ field, fieldState }) => {
          return (
            <S.SelectWrapper>
              <Select
                {...field}
                label={t`common.pigmented`}
                options={pigmentOptions}
                onChange={(val: string) => {
                  field.onChange(val);
                }}
                invalid={fieldState.invalid}
                hint={fieldState.error?.message}
                isSearchable={false}
              />
            </S.SelectWrapper>
          );
        }}
      />
      <Controller
        control={control}
        name="manufacturerId"
        rules={{ required: t`components.admin-view.manufacturer-required` }}
        render={({ field, fieldState }) => {
          return (
            <S.SelectWrapper>
              <Select
                {...field}
                label={t`common.manufacturer`}
                options={manufacturerOptions}
                invalid={fieldState.invalid}
                hint={fieldState.error?.message}
                isSearchable={false}
              />
            </S.SelectWrapper>
          );
        }}
      />
      <Controller
        control={control}
        name="capabilities"
        rules={{ required: t`components.admin-view.capabilities-required` }}
        render={({ field, fieldState }) => {
          return (
            <FormField label={t`common.simulation`}>
              <S.RadioFormField $invalid={fieldState.invalid}>
                <RadioGroup
                  name="capabilities"
                  onChange={field.onChange}
                  value={field.value}
                >
                  {simulationOptions.map((opt, i) => (
                    <RadioButton {...opt} key={i} />
                  ))}
                </RadioGroup>
              </S.RadioFormField>
              {fieldState.error?.message && (
                <S.RadioHint>{fieldState.error?.message}</S.RadioHint>
              )}
            </FormField>
          );
        }}
      />
      <SubmitCancelButtons onCancel={onClose} disableSubmit={!isDirty} />
    </form>
  );
};

const ManufacturerEditForm = ({
  manufacturer,
  onClose,
}: {
  manufacturer?: Manufacturer;
  onClose: () => void;
}) => {
  // If the `manufacturer` prop is provided, use the form for updating that manufacturer record.
  // Otherwise, create a new manufacturer.

  const defaultValues = manufacturer || { name: "", website: "" };

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({ defaultValues });

  const createManufacturer = useCreateManufacturer();
  const updateManufacturer = useUpdateManufacturer();

  const [_, addToast] = useToasts();

  const onSubmit = handleSubmit((data) => {
    const { name, website } = data;

    if (manufacturer) {
      const update: ManufacturerUpdate = {
        name,
        website,
      };

      updateManufacturer.mutateAsync({
        id: manufacturer?.id,
        update,
        onError: (code?: number) => {
          const title =
            code === 403
              ? t`components.admin-view.action-permission-denied`
              : t`components.admin-view.manufacturer-update-failed`;

          addToast({
            title,
            kind: "warning",
            timeout: 5_000,
          });
        },
      });
    } else if (name && website) {
      const newManufacturer: NewManufacturer = {
        name,
        website,
      };

      createManufacturer.mutateAsync({
        newManufacturer,
        onError: (code?: number) => {
          const title =
            code === 403
              ? t`components.admin-view.action-permission-denied`
              : t`components.admin-view.manufacturer-creation-failed`;

          addToast({
            title,
            kind: "warning",
            timeout: 5_000,
          });
        },
      });
    }

    onClose?.();
  });

  // Custom validation function to check that the url starts with `https://`
  const validateHttpsUrl = (value: string) => {
    const httpsPattern = /^https:\/\/.+$/;
    if (!httpsPattern.test(value))
      return t`components.admin-view.url-must-be-https`;
    if (!URL.canParse(value)) return t`components.admin-view.url-invalid`;
    return true;
  };

  return (
    <form onSubmit={onSubmit}>
      <FormTextField
        control={control}
        name="name"
        label={t`common.name`}
        rules={{
          required: t`components.admin-view.name-required`,
        }}
        placeholder={t`components.admin-view.manufacturer-name`}
      />
      <FormTextField
        control={control}
        name="website"
        label={t`common.website`}
        rules={{
          validate: validateHttpsUrl,
          required: t`components.admin-view.name-required`,
        }}
        placeholder={t`common.website`}
      />
      <SubmitCancelButtons onCancel={onClose} disableSubmit={!isDirty} />
    </form>
  );
};

const ArchiveMaterialForm = ({
  material,
  onClose,
}: {
  material: Material;
  onClose: () => void;
}) => {
  const updateMaterial = useUpdateMaterial();

  const [_, addToast] = useToasts();

  function onSubmit() {
    updateMaterial.mutateAsync({
      id: material.id,
      update: { archived: true },
      onError: (code?: number) => {
        const title =
          code === 403
            ? t`components.admin-view.action-permission-denied`
            : t`components.admin-view.material-archiving-failed`;

        addToast({
          title,
          kind: "warning",
          timeout: 5_000,
        });
      },
    });

    onClose?.();
  }

  return (
    <>
      <S.ArchiveMessage>
        {t`components.admin-view.confirm-archive-material`}
      </S.ArchiveMessage>
      <SubmitCancelButtons
        onCancel={onClose}
        onSubmit={onSubmit}
        submitButtonLabel="Archive"
        disableSubmit={false}
      />
    </>
  );
};

const ComingSoonForm = ({ onClose }: { onClose: () => void }) => {
  return (
    <div
      style={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        gap: "50px",
      }}
    >
      <S.ComingSoonNote>
        <p>
          <b>Coming Soon</b>
        </p>
        <p>This function is not implemented yet, but will be added soon.</p>
      </S.ComingSoonNote>
      <SubmitCancelButtons onCancel={onClose} disableSubmit={false} />
    </div>
  );
};

const FactoryMachines = () => {
  const editModal = useModal<Machine | undefined>();
  const transferModal = useModal<Machine>();
  const comingSoonModal = useModal<void>();

  const { data: org } = useCurrentOrg();

  const { data: permissions } = useCurrentUserPermissions(org.domainId);

  const canEdit = testPermissions("machines/write", permissions);

  const actions = canEdit
    ? (machine: Machine) => [
        {
          render: t`components.admin-view.transfer-machine`,
          // TODO: this menu item should open a modal with a form to
          // pick the destination org.
          onClick: () => transferModal.openModal(machine),
        },
        {
          render: t`common.edit`,
          // TODO: I assume we can change serial number, production date,
          // and other info before shipping the machine.
          onClick: () => editModal.openModal(machine),
        },
        {
          render: t`common.archive`,
          // TODO: need to figure out the conditions that allow production folks to delete machines,
          // and exactly what should happen when a machine is deleted.
          onClick: comingSoonModal.openModal,
        },
        {
          render: t`components.admin-view.deactivate`,
          // TODO: need to figure out the conditions that allow production folks to delete machines,
          // and exactly what should happen when a machine is deleted.
          onClick: comingSoonModal.openModal,
        },
        {
          render: t`components.admin-view.activate-generate-keys`,
          // TODO: need to figure out the conditions that allow production folks to delete machines,
          // and exactly what should happen when a machine is deleted.
          onClick: comingSoonModal.openModal,
        },
      ]
    : undefined;

  return (
    <S.AdminContent>
      <S.AdminContentSection>
        <S.AdminContentSectionTitleContainer>
          <h2>{t`components.admin-view.factory-machines`}</h2>
          <PermissionTest
            domainId={ROOT_DOMAIN}
            requiredPermissions={"machines/create"}
            render={({ allowed }) => (
              <Button
                onClick={() => editModal.openModal(undefined)}
                disabled={!allowed}
              >{t`components.admin-view.create-machine`}</Button>
            )}
          />
        </S.AdminContentSectionTitleContainer>
        <AdminMachineListing orgId={org.id} actions={actions} />
      </S.AdminContentSection>
      <editModal.Modal
        title={
          editModal.modalData
            ? t`components.admin-view.edit-machine`
            : t`components.admin-view.create-machine`
        }
      >
        {editModal.isModalOpen && (
          <MachineDetailForm
            onClose={editModal.closeModal}
            machine={editModal.modalData}
          />
        )}
      </editModal.Modal>
      <transferModal.Modal title={t`components.admin-view.transfer-machine`}>
        {transferModal.modalData && (
          <MachineTransferForm
            onClose={transferModal.closeModal}
            machine={transferModal.modalData}
          />
        )}
      </transferModal.Modal>
      <comingSoonModal.Modal title={"Coming Soon"}>
        {comingSoonModal.isModalOpen && (
          <ComingSoonForm onClose={comingSoonModal.closeModal} />
        )}
      </comingSoonModal.Modal>
      <S.ComingSoonNote>
        <p>
          <b>Coming Soon</b>
        </p>
        <p>
          In addition to the table above showing machines in the Manufacturing
          org, this page will also show three more tables: Other AON3D Machines,
          Customer Machines, and Archived Machines.
        </p>
      </S.ComingSoonNote>
    </S.AdminContent>
  );
};

const Manufacturers = ({
  actions,
}: {
  actions?: (machine: Manufacturer) => MenuItem[];
}) => {
  const { data: manufacturers } = useManufacturers();
  if (!manufacturers?.length) return null;

  const columns: ColumnDef<Manufacturer>[] = [
    {
      id: "name",
      header: t`common.name`,
      accessorKey: "name",
      cell: ({ row: { original: rowData } }) => (
        // The ID here is to enable linking from the materials table.
        <div id={rowData.id}>{rowData.name}</div>
      ),
    },
    {
      id: "website",
      header: t`common.website`,
      accessorKey: "website",
      cell: ({ row: { original: rowData } }) => (
        <a href={rowData.website} rel="noreferrer" target="_blank">
          {rowData.website}
        </a>
      ),
    },
    {
      id: "id",
      header: t`common.id`,
      accessorKey: "id",
      cell: ({ row: { original: rowData } }) => (
        <UuidTooltip uuid={rowData.id} />
      ),
    },
  ];

  if (actions) {
    columns.push({
      id: "actions",
      header: t`common.actions`,
      cell: ({ row: { original: rowData } }) => (
        <OverlayMenu
          target={
            <Button kind="secondary" size="small" icon>
              <Icon variant="MoreVert" />
            </Button>
          }
          placement="bottomStart"
          items={actions(rowData)}
        />
      ),
    });
  }

  return (
    <>
      <Table
        columns={columns}
        data={manufacturers}
        initialState={{ sorting: [{ id: "name", desc: false }] }}
      />
    </>
  );
};

// Calculates a numerical value for a material's simulation capabilities to enable sorting.
const getRowCapabilityNumberValue = (row: Row<Material>) => {
  const { simulation, optimization } = row.original.capabilities;
  if (optimization) return 2;
  if (simulation) return 1;
  return 0;
};

// Column definitions shared between the Materials and Archived Materials tables.
// Formatted as a function that returns the array of `ColumnDef`s so that the `lingui`
// macro will continue to work as expected.
const sharedMaterialTableColumns: () => ColumnDef<Material>[] = () => [
  {
    id: "name",
    accessorKey: "name",
    header: t`common.name`,
    cell: ({ row: { original: rowData } }) => rowData.name,
  },
  {
    id: "color",
    accessorKey: "color",
    header: t`common.color`,
    cell: ({ row: { original: rowData } }) => (
      <S.MaterialColorContainer>
        <S.MaterialColorBox $color={rowData.color} />
        {rowData.color.toUpperCase()}
      </S.MaterialColorContainer>
    ),
  },
  {
    id: "pigmented",
    accessorKey: "pigmented",
    header: t`common.pigmented`,
    cell: ({ cell }) => cell.getValue() && <S.Checkmark>✓</S.Checkmark>,
  },
  {
    id: "manufacturer",
    header: t`common.manufacturer`,
    accessorKey: "manufacturer.name",
    cell: ({ row: { original: rowData } }) => (
      <a href={`#${rowData.manufacturer.id}`}>{rowData.manufacturer.name}</a>
    ),
  },
  {
    id: "simulatable",
    header: t`common.simulatable`,
    accessorKey: "capabilities",
    sortingFn: (rowA, rowB) => {
      return (
        getRowCapabilityNumberValue(rowB) - getRowCapabilityNumberValue(rowA)
      );
    },
    cell: ({ row: { original: rowData } }) => {
      const { simulation, optimization } = rowData.capabilities;

      if (!simulation) {
        return null;
      }

      return <S.Checkmark>{`✓${optimization ? " + Opt" : ""}`}</S.Checkmark>;
    },
  },
  {
    id: "id",
    header: t`common.id`,
    accessorKey: "id",
    cell: ({ row: { original: rowData } }) => <UuidTooltip uuid={rowData.id} />,
  },
];

const ArchivedMaterials = ({
  actions,
}: {
  actions: (material: Material) => MenuItem[];
}) => {
  const { data: materials } = useMaterials();

  const archivedMaterials = materials?.filter((material) => material.archived);

  if (!archivedMaterials || !archivedMaterials?.length) return null;

  const columns: ColumnDef<Material>[] = [
    ...sharedMaterialTableColumns(),
    {
      id: "archivedAt",
      header: t`common.archived-at`,
      accessorKey: "updatedAt",
      cell: ({ row: { original: rowData } }) => (
        <DateTime value={rowData.updatedAt} />
      ),
    },
    {
      id: "actions",
      header: t`common.actions`,
      cell: ({ row: { original: rowData } }) => (
        <OverlayMenu
          target={
            <Button kind="secondary" size="small" icon>
              <Icon variant="MoreVert" />
            </Button>
          }
          placement="bottomStart"
          items={actions(rowData)}
        />
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        data={archivedMaterials}
        initialState={{ sorting: [{ id: "name", desc: false }] }}
      />
    </>
  );
};

const Materials = () => {
  const materialsModal = useModal<Material>();
  const archiveMaterialsModal = useModal<Material>();

  const manufacturersModal = useModal<Manufacturer>();

  const { data: materials } = useMaterials();

  const updateMaterial = useUpdateMaterial();
  const [_, addToast] = useToasts();

  const { data: rootPermissions } = useCurrentUserPermissions(ROOT_DOMAIN);

  const availableMaterials =
    materials?.filter((material) => !material.archived) ?? [];

  if (!availableMaterials?.length) return null;

  const canEditMaterials = testPermissions("materials/write", rootPermissions);

  const canEditManufacturers = testPermissions(
    "manufacturers/write",
    rootPermissions
  );

  const materialActions = (material: Material) =>
    canEditMaterials
      ? [
          {
            render: t`common.edit`,
            onClick: () => materialsModal.openModal(material),
          },
          {
            render: t`common.archive`,
            onClick: () => archiveMaterialsModal.openModal(material),
          },
        ]
      : [];

  const archivedMaterialActions = (material: Material) =>
    canEditMaterials
      ? [
          {
            render: t`common.unarchive`,
            onClick: () => {
              updateMaterial.mutateAsync({
                id: material.id,
                update: { archived: false },
                onError: (code?: number) => {
                  const title =
                    code === 403
                      ? t`components.admin-view.action-permission-denied`
                      : t`components.admin-view.material-unarchiving-failed`;

                  addToast({
                    title,
                    kind: "warning",
                    timeout: 5_000,
                  });
                },
              });
            },
          },
        ]
      : [];

  const manufacturerActions = (manufacturer: Manufacturer) =>
    canEditManufacturers
      ? [
          {
            render: t`common.edit`,
            onClick: () => manufacturersModal.openModal(manufacturer),
          },
        ]
      : [];

  const columns: ColumnDef<Material>[] = [
    ...sharedMaterialTableColumns(),
    {
      id: "actions",
      header: t`common.actions`,
      cell: ({ row: { original: rowData } }) => (
        <OverlayMenu
          target={
            <Button kind="secondary" size="small" icon>
              <Icon variant="MoreVert" />
            </Button>
          }
          placement="bottomStart"
          items={materialActions(rowData)}
        />
      ),
    },
  ];

  return (
    <S.AdminContent>
      <S.AdminContentSection>
        <S.AdminContentSectionTitleContainer>
          <h2></h2>
          <PermissionTest
            domainId={ROOT_DOMAIN}
            requiredPermissions={"materials/create"}
            render={({ allowed }) => (
              <Button
                onClick={() =>
                  materialsModal.openModal(materialsModal.modalData)
                }
                disabled={!allowed}
              >{t`common.create`}</Button>
            )}
          />
        </S.AdminContentSectionTitleContainer>
        <Table
          columns={columns}
          data={availableMaterials}
          initialState={{ sorting: [{ id: "name", desc: false }] }}
        />
      </S.AdminContentSection>
      <S.AdminContentSection>
        <S.AdminContentSectionTitleContainer>
          <h2>{t`common.manufacturers`}</h2>
          <PermissionTest
            domainId={ROOT_DOMAIN}
            requiredPermissions={"manufacturers/create"}
            render={({ allowed }) => (
              <Button
                onClick={() =>
                  manufacturersModal.openModal(manufacturersModal.modalData)
                }
                disabled={!allowed}
              >{t`common.create`}</Button>
            )}
          />
        </S.AdminContentSectionTitleContainer>
        <Manufacturers actions={manufacturerActions} />
      </S.AdminContentSection>
      <S.AdminContentSection>
        <S.AdminContentSectionTitleContainer>
          <h2>{t`components.admin-view.archived-materials`}</h2>
        </S.AdminContentSectionTitleContainer>
        <ArchivedMaterials actions={archivedMaterialActions} />
      </S.AdminContentSection>
      <S.AdminContentSection>
        <S.ComingSoonNote>
          <p>
            We may extend this page to allow us to manage the Basis material
            database. Longer-term, we also may allow customers to add their own
            custom materials, or alternatively we may do that for them.
          </p>
        </S.ComingSoonNote>
      </S.AdminContentSection>
      <archiveMaterialsModal.Modal
        title={t`components.admin-view.archive-material`}
      >
        {archiveMaterialsModal.isModalOpen &&
          archiveMaterialsModal.modalData && (
            <ArchiveMaterialForm
              material={archiveMaterialsModal.modalData}
              onClose={archiveMaterialsModal.closeModal}
            />
          )}
      </archiveMaterialsModal.Modal>
      <materialsModal.Modal
        title={
          materialsModal.modalData
            ? t`components.admin-view.edit-material`
            : t`components.admin-view.create-material`
        }
      >
        {materialsModal.isModalOpen && (
          <MaterialEditForm
            material={materialsModal.modalData}
            onClose={materialsModal.closeModal}
          />
        )}
      </materialsModal.Modal>
      <manufacturersModal.Modal
        title={
          manufacturersModal.modalData
            ? t`components.admin-view.edit-manufacturer`
            : t`components.admin-view.create-manufacturer`
        }
      >
        {manufacturersModal.isModalOpen && (
          <ManufacturerEditForm
            manufacturer={manufacturersModal.modalData}
            onClose={manufacturersModal.closeModal}
          />
        )}
      </manufacturersModal.Modal>
    </S.AdminContent>
  );
};

const Content = () => {
  return (
    <S.ComingSoonNote>
      <p>
        <b>Coming Soon</b>
      </p>
      <p>
        This page will enable managing the Basis changelog and other content in
        the right pane of the Basis home page.
      </p>
      <p>
        The goal is to allow Marketing and PM to self-serve changes without
        requiring PRs to change content.
      </p>
    </S.ComingSoonNote>
  );
};

// Component to display when the user does not have permission to view the org.
function InsufficientOrgPerms() {
  return (
    <S.OrgDeniedContainer>
      <S.OrgDeniedMessage>
        {t`components.admin-view.insufficient-org-perms`}
      </S.OrgDeniedMessage>
    </S.OrgDeniedContainer>
  );
}

const OrgOverview = () => {
  const [_, orgOverviewParams] = useRoute(adminRoutes.organization);
  const orgId = orgOverviewParams?.id;
  const { data: org } = useOrg(orgId);
  const renameModal = useModal<Machine>();
  const inviteModal = useModal<Org>();
  const comingSoonModal = useModal<void>();
  const { data: orgs } = useOrgs();
  const { data: currentOrg } = useCurrentOrg();

  // TODO: scope all of these to the org.
  const { data: _materials } = useMaterials();

  // Filter out organizations that the user does not have permission to view
  const readOrgs = orgs.filter((org) =>
    org.permissions.includes(permissions.organizations.read)
  );

  // Check if the user has permission to view the org based on whether the org
  // is in the list of orgs the user can read.
  const canReadOrg = readOrgs.some((o) => o.id === orgId);

  // Check if the user is trying to view the current org.
  const viewingCurrentOrg = currentOrg?.id === orgId;

  if (!org || !canReadOrg) {
    // If the user does not have permission to view the the current org, display
    // an error message rather than redirecting to the main admin page; redirecting
    // in this case could cause an infinite loop. This case should never happen, as
    // the user should have read permissions to an org they are a member of.
    return viewingCurrentOrg ? (
      <InsufficientOrgPerms />
    ) : (
      <Redirect to={adminUrls.index} />
    );
  }

  const actions = (machine: Machine) => [
    {
      render: t`common.support`,
      onClick: () => {
        // Construct mailto for getting machine specific support.
        const uri = [
          `mailto:help@aon3d.com`,
          `?subject=Basis Machine Support [${machine.hardware.serial}]`,
        ].join("");
        window.open(encodeURI(uri));
      },
    },
    {
      render: t`common.rename`,
      // TODO: I assume we can change serial number, production date,
      // and other info before shipping the machine.
      onClick: () => renameModal.openModal(machine),
    },
  ];

  return (
    <S.AdminContent>
      <S.AdminContentSection>
        <S.AdminContentSectionTitleContainer>
          <h2>{t`common.machines`}</h2>
        </S.AdminContentSectionTitleContainer>
        <AdminMachineListing orgId={orgId} actions={actions} />
      </S.AdminContentSection>
      <S.AdminContentSection>
        <S.AdminContentSectionTitleContainer>
          <h2>{t`common.users`}</h2>
          <PermissionTest
            domainId={org.domainId}
            requiredPermissions={"organizations/invite"}
            render={({ allowed }) => (
              <Button
                disabled={!allowed}
                onClick={() => inviteModal.openModal(org)}
              >
                {t`common.invite-user`}
                {inviteModal.modalData && (
                  <inviteModal.Modal
                    title={t`common.invite-user-to-org ${org.name}`}
                  >
                    <InviteUserForm
                      org={org}
                      onClose={inviteModal.closeModal}
                    />
                  </inviteModal.Modal>
                )}
              </Button>
            )}
          />
        </S.AdminContentSectionTitleContainer>
        <UsersList org={org} />
      </S.AdminContentSection>
      <renameModal.Modal title={t`components.admin-view.rename-machine`}>
        {renameModal.modalData && (
          <MachineRenameForm
            onClose={renameModal.closeModal}
            machine={renameModal.modalData}
          />
        )}
      </renameModal.Modal>
      <comingSoonModal.Modal title={"Coming Soon"}>
        {comingSoonModal.isModalOpen && (
          <ComingSoonForm onClose={comingSoonModal.closeModal} />
        )}
      </comingSoonModal.Modal>
    </S.AdminContent>
  );
};

export const AdminView = () => {
  const isAonUser = useIsAonUser();
  const { data: rootPermissions } = useCurrentUserPermissions(ROOT_DOMAIN);
  const { data: currentOrg } = useCheckCurrentOrg();

  const { routes, allAccessibleTabs, showTabBar } = useMemo(() => {
    const tabs: TabInfo[] = [
      {
        name: t`components.admin-view.organizations`,
        route: adminRoutes.wildcards.organizations,
        url: adminUrls.organizations,
        title: {
          [adminRoutes.organization]: <OrgName />,
          [adminRoutes.organizations]: t`components.admin-view.organizations`,
        },
        Component: () => <Organizations />,
        requiredPermissions: [["organizations/read"], ["machines/read"]],
      },
      {
        name: t`components.admin-view.machine-production`,
        route: adminRoutes.machines,
        url: adminUrls.machines,
        Component: () => <FactoryMachines />,
        requiredPermissions: "machines/read",
      },
      {
        name: t`common.materials`,
        route: adminRoutes.materials,
        url: adminUrls.materials,
        Component: () => <Materials />,
        requiredPermissions: "materials/read",
      },
      {
        name: t`components.admin-view.content`,
        route: adminRoutes.content,
        url: adminUrls.content,
        Component: () => <Content />,
        requiredPermissions: "views/superadmin",
      },
    ];

    // Filter out tabs that the user doesn't have permission to see
    const filteredTabs = tabs.filter((tab) =>
      testPermissions(tab.requiredPermissions ?? [], rootPermissions)
    );

    // If there are no tabs the user can see, don't show the tab bar
    const showTabBar = isAonUser && !!filteredTabs.length;

    const searchTab = {
      name: t`components.admin-view.search`,
      route: adminRoutes.search,
      url: adminUrls.search,
      Component: () => <AdminSearch />,
    };

    // Redirect URL for managing the current org. If no current org is set,
    // redirect to the Organizations tab of the settings page.
    const currentOrgRedirectUrl = currentOrg
      ? adminUrls.organization(currentOrg.id)
      : settingsUrls.organizations;

    // Redirect URL for internal users. If the tab bar is displayed for this user,
    // redirect to the first accessible tab. Otherwise, use the redirect for the
    // current org defined above.
    const redirectUrl = showTabBar
      ? filteredTabs[0].url
      : currentOrgRedirectUrl;

    // Add search tab to the list of tabs
    const allAccessibleTabs = [...filteredTabs, searchTab];

    return {
      routes: !isAonUser ? (
        // Non-internal users can only see the manage page for their own org
        <Switch>
          <Route path={adminRoutes.organization}>
            <OrgOverview />
          </Route>
          <Redirect to={currentOrgRedirectUrl} replace />
        </Switch>
      ) : (
        <Switch>
          <Route path={adminRoutes.organization}>
            <OrgOverview />
          </Route>
          {allAccessibleTabs.map((tab) => (
            <Route key={tab.route} path={tab.route}>
              <tab.Component />
            </Route>
          ))}
          <Redirect to={redirectUrl} replace />
        </Switch>
      ),
      allAccessibleTabs,
      showTabBar,
    };
  }, [currentOrg, rootPermissions, isAonUser]);

  return (
    <S.Wrapper>
      <TopLevelTabs tabs={allAccessibleTabs} showTabBar={showTabBar} />
      <S.MainContent>{routes}</S.MainContent>
    </S.Wrapper>
  );
};
