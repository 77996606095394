import React, {
  ReactElement,
  useMemo,
  forwardRef,
  ReactNode,
  Ref,
} from "react";
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  Props,
  PropsValue,
  MultiValue,
  SingleValue,
  ClearIndicatorProps,
  SelectInstance,
  SingleValueProps,
  MultiValueGenericProps,
} from "react-select";
export type { StylesConfig } from "react-select";
import { find, propEq } from "ramda";

import {
  FormFieldProps,
  wrapLabelHeader,
  appendHint,
} from "_/components/form-field";
import { Icon } from "_/components/icon";

import * as S from "./styled";

/**
 * Options used in the select must contain at minimum a label and value which
 * can be rendered. They may extend this and include additional fields which
 * can be used by a custom renderer.
 *
 * For most simple use cases where no additional custom rendering is required
 * using option data, it is sufficient to use the Option interface as a direct
 * type.
 */
export interface Option<T> {
  /**
   * Text to be displayed for option in `<Select />` component
   */
  label: string;

  /**
   * Optional JSX rendered in place of the label text. `label` is still
   * required for accessibility.
   */
  labelJsx?: ReactNode;

  /**
   * Optional JSX rendered in place of the value label text. */
  selectedValueJsx?: ReactNode;

  /**
   * Operative value of option in `<Select />` component
   */
  value: T;
}

export type SelectProps<
  Opt extends Option<Opt["value"]>,
  IsMulti extends boolean = false,
  Group extends GroupBase<Opt> = GroupBase<Opt>,
> = FormFieldProps &
  Omit<
    Props<Opt, IsMulti, Group>,
    | "options"
    | "theme"
    | "value"
    | "defaultValue"
    | "isMulti"
    | "isDisabled"
    | "onChange"
    | "classNames"
  > & {
    options: Opt[];

    /**
     * Select value, or a list of multiple values.
     *
     * This will change the required signature of the onChange handler, so if a
     * select is required to work with both single and multiple values it is best to
     * wrap the single value in `[]`, to simplify the onChange handler.
     */
    value?: IsMulti extends true
      ? MultiValue<Opt["value"]>
      : SingleValue<Opt["value"]>;

    defaultValue?: PropsValue<Opt["value"]>;

    /**
     * Direct prop mapping to the react-select `isMulti` prop.
     *
     * This is overridden in order to preserve native `<select />` attribute
     * usage as much as possible and defaults to false.
     */
    multiple?: boolean;

    /**
     * Direct prop mapping to the react-select `isDisabled` prop.
     *
     * This is overridden in order to preserve native `<select />` attribute
     * usage as much as possible and defaults to false.
     */
    disabled?: boolean;

    /**
     * Sets styling of `<Select />` to warn that the current state is invalid
     */
    invalid?: boolean;

    /**
     * Overrides the `onChange(...)` callback provided by `react-select` to provide
     * an interface similar to the HTML `<select />` element
     */
    onChange?: IsMulti extends true
      ? (newValue: Opt["value"][] | null) => void
      : (newValue: Opt["value"] | null) => void;
  };

const formatOptionLabel = ({
  label,
  labelJsx,
}: {
  label: string;
  labelJsx: ReactNode;
}) => labelJsx ?? label;

const SingleValueLabel = <T,>(props: SingleValueProps<Option<T>>) => {
  const { label, labelJsx, selectedValueJsx } = props.data;
  return (
    <components.SingleValue {...props}>
      {selectedValueJsx ?? labelJsx ?? label}
    </components.SingleValue>
  );
};

const MultiValueLabel = <T,>(props: MultiValueGenericProps<Option<T>>) => {
  const { label, labelJsx, selectedValueJsx } = props.data;
  return (
    <components.MultiValueLabel {...props}>
      {selectedValueJsx ?? labelJsx ?? label}
    </components.MultiValueLabel>
  );
};

const DropdownIndicator = <Opt,>(props: DropdownIndicatorProps<Opt>) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon variant="ArrowDropDown" label="select-dropdown" />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = <Opt,>(props: ClearIndicatorProps<Opt>) => {
  return (
    <components.ClearIndicator {...props}>
      <Icon variant="Close" label="select-clear" />
    </components.ClearIndicator>
  );
};

function _Select<
  Opt extends Option<Opt["value"]>,
  IsMulti extends boolean = false,
>(
  props: SelectProps<Opt, IsMulti>,
  ref: Ref<SelectInstance<Opt, boolean, GroupBase<Opt>>> | undefined
): ReactElement {
  const {
    invalid,
    options,
    value,
    defaultValue,
    multiple,
    disabled,
    onChange,
    label,
    hint,
    help,
    className,
    ...restProps
  } = props;

  // Reconstruct the default value for react-select from the passed in value.
  //
  // If there is no specified value prop, the component is not a controlled
  // component so we allow for `<ReactSelect />` to manage state and set the
  // value prop to `undefined`.
  const setValue = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }

    if (Array.isArray(value)) {
      return value.reduce((acc, val) => {
        const v = find(propEq(val, "value"), options);
        acc.push(v);
        return acc;
      }, [] as Opt[]);
    }

    return options.find((opt) => opt.value === value);
  }, [value, options]);

  // Reconstruct the default value for react-select from the passed in value.
  const setDefaultValue = useMemo(() => {
    return find(propEq(defaultValue, "value"), options);
  }, [defaultValue, options]);

  // The "className" and "classNamePrefix" props on <ReactSelect /> are used to
  // facilitate styling with styled-components.
  //
  // "classNamePrefix" sets a human-readable string to prefix all classes
  // targeting <ReactSelect />'s subcomponents and special states of those
  // subcomponents.
  const select = (
    <S.Wrapper $invalid={invalid} className={className}>
      <ReactSelect
        {...restProps}
        ref={ref}
        className="react-select-container"
        classNamePrefix="react-select"
        components={{
          ClearIndicator,
          DropdownIndicator,
          IndicatorSeparator: () => null,
          SingleValue: SingleValueLabel,
          MultiValueLabel: MultiValueLabel,
        }}
        options={options}
        defaultValue={setDefaultValue}
        value={setValue}
        isMulti={multiple}
        isDisabled={disabled}
        onChange={(change) => {
          if (Array.isArray(change)) {
            onChange?.(change.map((opt) => opt.value));
          } else if (change === null) {
            onChange?.(null);
          } else {
            onChange?.(change.value);
          }
        }}
        formatOptionLabel={formatOptionLabel}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => {
          return option.value;
        }}
      />
    </S.Wrapper>
  );

  let selectFormField = select;

  // Add the Label and Help icon above the Select component if they are defined
  if (label) {
    selectFormField = wrapLabelHeader(selectFormField, label, help, className);
  }

  // Add hint message below the Select component it is defined
  if (hint) selectFormField = appendHint(selectFormField, hint, invalid);

  return selectFormField;
}

export const Select = forwardRef(_Select);
