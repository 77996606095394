import React, { useRef, useEffect, useMemo } from "react";
import {
  Group,
  Vector2,
  BufferGeometry,
  BufferAttribute,
  ShaderMaterial,
  Points,
  DataTexture,
  RGBAFormat,
} from "three";
import { useThree } from "@react-three/fiber";
import { scaleSequential } from "d3-scale";
import { interpolateTurbo } from "d3-scale-chromatic";
import { rgb } from "d3-color";
import { useAtom } from "jotai";

import { View, ViewMode } from "_/state/viewer";

import pointsVert from "./glsl/points.vert";
import pointsFrag from "./glsl/points.frag";

function generateLookupTexture() {
  // Color scale used to map sensor values to colors
  const scale = scaleSequential(interpolateTurbo).domain([0, 1]);

  // Generate a lookup texture to use the color scale in the shader
  const textureWidth = 256;
  const data = new Uint8Array(textureWidth * 4); // RGBA

  for (let i = 0; i < textureWidth; i++) {
    const color = rgb(scale(i / textureWidth));
    const offset = i * 4;
    data[offset] = color.r;
    data[offset + 1] = color.g;
    data[offset + 2] = color.b;
    data[offset + 3] = 255;
  }

  const lookupTexture = new DataTexture(data, textureWidth, 1, RGBAFormat);
  lookupTexture.needsUpdate = true;
  return lookupTexture;
}

type PrintViewProps = {
  view: View<ViewMode.Print>;
};

export const PrintView = ({ view }: PrintViewProps) => {
  const { invalidate } = useThree();

  const groupRef = useRef<Group>(null);

  const [viewControls] = useAtom(view.controlsAtom);

  const { zRange, valueRange, colorRange, pointSize, showNonExtrusionMotion } =
    viewControls;

  const geometry = useMemo(() => {
    const geometry = new BufferGeometry();

    geometry.setAttribute(
      "position",
      new BufferAttribute(view.data.positions, 3)
    );

    geometry.setAttribute(
      "sensor",
      new BufferAttribute(view.data.sensorValues, 1)
    );

    geometry.setAttribute(
      "extrusion",
      new BufferAttribute(view.data.extrusionValues, 1)
    );

    return geometry;
  }, [view.data]);

  const material = useMemo(
    () =>
      new ShaderMaterial({
        uniforms: {
          pointSize: { value: pointSize.range[1] / 2 },
          lookupTexture: { value: generateLookupTexture() },
          zRange: { value: new Vector2() },
          valueRange: {
            value: new Vector2(),
          },
          colorLimit: {
            value: new Vector2(),
          },
          showNonExtrusionMotion: {
            value: false,
          },
        },
        vertexShader: pointsVert,
        fragmentShader: pointsFrag,
      }),
    [pointSize.range]
  );

  useEffect(() => {
    if (groupRef.current) {
      const points = new Points(geometry, material);
      groupRef.current.add(points);
    }
  }, [geometry, material]);

  // Update shader material uniforms based on control changes
  useEffect(() => {
    material.uniforms.zRange.value.x = zRange.value[0];
    material.uniforms.zRange.value.y = zRange.value[1];
    material.uniforms.valueRange.value.x = valueRange.value[0];
    material.uniforms.valueRange.value.y = valueRange.value[1];
    material.uniforms.colorLimit.value.x = colorRange.value[0];
    material.uniforms.colorLimit.value.y = colorRange.value[1];
    material.uniforms.pointSize.value = pointSize.value;
    material.uniforms.showNonExtrusionMotion.value = showNonExtrusionMotion;
    invalidate();
  }, [
    zRange.value,
    valueRange.value,
    colorRange.value,
    pointSize.value,
    showNonExtrusionMotion,
    material,
    invalidate,
  ]);

  return <group ref={groupRef} />;
};
