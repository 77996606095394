import React, { ReactElement, Ref, forwardRef } from "react";

import { Select, Option, SelectProps } from "_/components/select";
import { UserAvatar } from "_/components/avatar";
import { User, useUsers } from "_/data/users";
import { Uuid } from "_/types";

interface OptionType {
  value: Uuid;
  label: string;
  user: User;
}

type UserSelectionProps = Omit<SelectProps<Option<OptionType>>, "options">;

/**
 * Select component for displaying users list dropdown.
 *
 * This will by default show all users that are visible by the currently logged in user.
 */
const _UserSelect = (
  props: UserSelectionProps,
  ref: Ref<typeof Select<OptionType>> | undefined
): ReactElement => {
  const { data: users = [] } = useUsers();

  const options = users.map((u) => ({
    value: u.id,
    label: u.name,
    user: u,
  }));

  const formatter = (opt: (typeof options)[number]) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <UserAvatar resource={opt.user} size="small" />
        <span style={{ marginLeft: "10px" }}>{opt.user.name}</span>
      </div>
    );
  };

  return (
    <Select
      {...props}
      ref={ref}
      options={options}
      formatOptionLabel={formatter}
    />
  );
};

export const UserSelect = forwardRef(_UserSelect);
