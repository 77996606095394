import {
  useQuery,
  UseQueryOptions,
  useQueryClient,
  useMutation,
} from "react-query";
import { t } from "@lingui/macro";

import { api, ApiResponse } from "_/utils";
import { Uuid } from "_/types";

import { useCheckUser, useCurrentUser } from "_/data/users";
import { Permission } from "./definitions";

export { permissions } from "./definitions";
export type {
  Permission,
  Permissions,
  PermissionResource,
  PermissionAction,
} from "./definitions";

const SCOPE = "rbac";

const KEYS = {
  roles: (userId: Uuid, domainId?: Uuid, direct?: boolean) => {
    const key: (Uuid | boolean | undefined)[] = [SCOPE, userId, domainId];
    if (direct !== undefined) {
      key.push(direct);
    }
    return key;
  },
  domainName: (id: Uuid) => [SCOPE, "domainNames", id],
  assignableRoles: (domainId: Uuid) => [SCOPE, "assignableRoles", domainId],
};

const PERMISSIONS_SCOPE = "permissions";

export const PERMISSIONS_KEYS = {
  all: () => [PERMISSIONS_SCOPE],
  currentUser: (domainId: Uuid) => [
    PERMISSIONS_SCOPE,
    "domainPermissions",
    domainId,
  ],
};

export const ROOT_DOMAIN = "00000000-0000-0000-0000-000000000000";

// Hook that returns true if the current user has admin privileges on the root domain.
export function useIsRootAdmin(): boolean | undefined {
  const { data: currentUser } = useCurrentUser();

  const { data: domainRoles } = useUserRoles(currentUser.id, ROOT_DOMAIN, true);

  // Superusers can see global roles UI, even if they lack the superadmin role
  // in the root domain. This is a temporary fix for dev environments.
  if (currentUser?.superadmin) return true;

  const adminRoles = ["admin", "superadmin"] as Role[];

  if (domainRoles === undefined) {
    return undefined;
  } else {
    const roles = domainRoles.map((dr) => dr.role);
    return adminRoles.some((role) => roles.includes(role));
  }
}

// Eventually we'll want to figure out a way of populating this directly from
// Rust so we don't have to manually update when a new role is added.
export const globalRoles = [
  "superadmin",
  "organization_viewer",
  "organization_manager",
  "factory_user",
  "factory_manager",
  "materials_manager",
  "content_manager",
] as const;

// Built-in roles that should let the user see admin tools UI
export const adminRoles = [
  ...globalRoles,
  "owner",
  "organization_admin",
] as const;

// All built-in roles.
export const allRoles = [...adminRoles, "member"] as const;

/**
 * Role alias.
 */
export type Role = (typeof allRoles)[number];

export function translateRoleName(role: Role) {
  switch (role) {
    case "member":
      return t`common.member-role`;
    case "owner":
      return t`common.owner`;
    case "organization_admin":
      return t`common.organization-admin`;
    case "superadmin":
      return t`common.superadmin`;
    case "factory_user":
      return t`common.factory-user`;
    case "factory_manager":
      return t`common.factory-manager`;
    case "organization_viewer":
      return t`common.organization-viewer`;
    case "organization_manager":
      return t`common.organization-manager`;
    case "materials_manager":
      return t`common.materials-manager`;
    case "content_manager":
      return t`common.content-manager`;
    default:
      throw new Error(`Unknown role: ${role}`);
  }
}

/**
 * {domain ID, role name} pair used for assigning, removing and retrieving user roles.
 * Indicates that a user has the named role on the domain with the provided domain ID.
 */
export type DomainRole = {
  domainId: Uuid;
  role: Role;
};

/**
 * {domain ID, permission} pair.
 * Indicates that a user has the named permission on the domain with the provided domain ID.
 */
export type DomainPermission = {
  domain_id: Uuid;
  permission: Permission;
};

/**
 * Retrieve roles for the specified user and domain.
 *
 * This method should be used to retrieve the listing of capabilities
 * for display purposes and ensuring only available features are rendered in
 * the client, but all authorization must be handled server side.
 */
async function userRoles({
  userId,
  domainId,
  direct,
}: {
  userId: Uuid;
  domainId?: Uuid;
  direct?: boolean;
}): Promise<DomainRole[]> {
  const response = await api.get<ApiResponse<DomainRole[]>>(
    `/users/${userId}/roles`,
    {
      params: {
        domainId,
        direct,
      },
    }
  );

  return response.data.data;
}

/**
 * Query hook for [[`userRoles`]].
 */
export function useUserRoles(userId?: Uuid, domainId?: Uuid, direct?: boolean) {
  return useQuery({
    queryKey: userId ? KEYS.roles(userId, domainId, direct) : undefined,
    queryFn: userId
      ? () => userRoles({ userId, domainId, direct })
      : () => Promise.resolve([]),
    enabled: !!userId,
  });
}

/**
 * Assign and/or remove roles on a User.
 */
async function updateUserRoles({
  userId,
  update,
}: {
  userId: Uuid;
  update: {
    add?: DomainRole[];
    remove?: DomainRole[];
  };
}): Promise<void> {
  const { add, remove } = update;

  if (add && add.length > 0) {
    await api.post<ApiResponse<void>>(`/users/${userId}/roles`, add);
  }

  if (remove && remove.length > 0) {
    await api.delete<ApiResponse<void>>(`/users/${userId}/roles`, {
      data: remove,
    });
  }
}

/**
 * Mutation for [['updateUserRoles']].
 */
export function useUpdateUserRoles() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateUserRoles,
    onSuccess: (_data, vars) => {
      const { add = [], remove = [] } = vars.update;
      const combinedRoleUpdates = [...add, ...remove];
      const domainIds = combinedRoleUpdates.map((role) => role.domainId);
      const uniqueDomainIds = Array.from(new Set(domainIds));

      uniqueDomainIds.forEach((domainId) => {
        queryClient.invalidateQueries(KEYS.roles(vars.userId, domainId));
      });
    },
  });
}

/**
 * Retrieve permissions for the specified user and domain.
 */
async function userPermissions({
  userId,
  domainId,
  direct,
}: {
  userId: Uuid;
  domainId: Uuid;
  direct?: boolean;
}): Promise<Permission[]> {
  const response = await api.get<ApiResponse<DomainPermission[]>>(
    `/users/${userId}/permissions`,
    {
      params: {
        domainId,
        direct,
      },
    }
  );

  const domainPermissions = response.data.data;

  // Extract the permissions from the response data to return a flat array of type `Permission[]`.
  const permissions = domainPermissions.map(
    (domainPermission) => domainPermission.permission
  );

  return permissions ?? [];
}

/**
 * Query hook for initial retrieval of the permissions the current user has on the root domain.
 * This ensures that root domain permissions are defined before calling the `useCurrentUserPermissions`
 * hook on any private page.
 */
export function useCheckRootPermissions() {
  const { data: currentUser } = useCheckUser();

  return useQuery({
    queryFn: currentUser
      ? () => userPermissions({ userId: currentUser.id, domainId: ROOT_DOMAIN })
      : undefined,
    queryKey: PERMISSIONS_KEYS.currentUser(ROOT_DOMAIN),
    enabled: !!currentUser,
  });
}

/**
 * Query hook for retrieving the permissions the current user has on a given domain.
 */
export function useCurrentUserPermissions(domainId: Uuid) {
  const { data: currentUser } = useCurrentUser();

  const response = useQuery({
    queryFn: () =>
      userPermissions({ userId: currentUser.id, domainId, direct: false }),
    queryKey: PERMISSIONS_KEYS.currentUser(domainId),
  });

  const data = response.data;

  if (!data) {
    throw new Error("Permissions data is required but was not provided.");
  }

  return { ...response, data };
}

/**
 * Retrieve the display name for the specified domain.
 *
 * @param {Uuid} id Domain ID.
 */
async function getDomainName(id: Uuid): Promise<string> {
  const response = await api.get<ApiResponse<string>>(`/rbac/domains/${id}`);
  return response.data.data;
}

/**
 * Query hook for [[`getDomainName`]].
 *
 * @param {Uuid} id Domain ID.
 */
export function useDomainName(id: Uuid) {
  const config: UseQueryOptions<string> = {
    queryKey: KEYS.domainName(id),
    queryFn: async () => await getDomainName(id),
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  };

  return useQuery(config);
}

/**
 * Retrieve roles assignable by the current user on the given domain.
 */
async function getAssignableRoles(domainId: Uuid): Promise<Role[]> {
  const response = await api.get<ApiResponse<Role[]>>(
    `/rbac/domains/${domainId}/roles`
  );
  return response.data.data;
}

/**
 * Query hook for [[`getAssignableRoles`]].
 */
export function useAssignableRoles(domainId: Uuid) {
  const config: UseQueryOptions<Role[]> = {
    queryKey: KEYS.assignableRoles(domainId),
    queryFn: async () => await getAssignableRoles(domainId),
  };

  return useQuery(config);
}
