import { css, DefaultTheme, createGlobalStyle } from "styled-components";
import { useAtom } from "jotai";
import React, { ReactElement, useEffect } from "react";

import { themeAtom } from "_/state";

import { fonts } from "./fonts";
import { brandColors, palette } from "./colors";

export { brandColors, palette, materialColors } from "./colors";

/**
 * Theme variables for button styling.
 *
 * Some of these colors may be used in color functions to lighten, darken, or
 * transparentize in the components stylesheet.
 */
type ButtonTheme = {
  /** Default coloring of the button.  */
  default: string;

  /** Hover state color.  */
  hover: string;

  /** Mouse press color (i.e. `active`). */
  pressed: string;

  /** Color for disabled button state. */
  disabled: string;

  /** Main text color. */
  text: string;
};

type InputTheme = {
  /** Color of text */
  text: string;
  /** Color of border */
  border: string;
  /** Color of background */
  background: string;
};

declare module "styled-components" {
  interface DefaultTheme {
    name: "dark" | "light";
    dark: boolean;
    color: {
      brand: typeof brandColors;
      palette: typeof palette;
    };
    background: {
      primary: string;
      secondary: string;
      tertiary: string;
      quaternary: string;
    };
    badge: {
      background: string;
    };
    border: {
      default: string;
      focus: string;
      contrast: string; // grayscale border intended to be noticed, not subtle
    };
    text: {
      default: string;
      highlight: string;
      muted: string; // for labels and other text that should be less prominent
      hover: string;
      disabled: string;
      secondary: string; // de-emphasized text; between `default` and `muted`
    };
    button: {
      primary: ButtonTheme;
      secondary: ButtonTheme;
      danger: ButtonTheme;
    };
    modal: {
      background: string;
      header: string;
      border: string;
    };
    toast: {
      primary: string;
      secondary: string;
      success: string;
      danger: string;
      warning: string;
      info: string;
    };
    link: {
      default: string;
      hover: string;
      active: string;
      visited: string;
    };
    input: {
      default: InputTheme;
      hover: InputTheme;
      focused: InputTheme;
      invalid: InputTheme;
      disabled: InputTheme;
      colorScheme: string;
    };
    tooltip: {
      text: string;
      background: string;
      hoverButtonText: string;
      shadow: string;
    };
    header: {
      background: string;
    };
    sidebar: {
      background: string;
      border: string;
      handle: {
        background: string;
        text: string;
      };
      item: {
        text: {
          default: string;
          hover: string;
          active: string;
        };
        background: {
          hover: string;
          active: string;
        };
      };
    };
    subheader: {
      background: string;
      border: string;
    };
    projects: {
      card: {
        title: {
          default: string;
          hover: string;
        };
      };
    };
    progressBar: {
      text: string;
      progress: string;
      background: string;
    };
    overlayMenu: {
      border: string;
    };
    viewer: {
      background: string;
    };
    divider: {
      small: string; // e.g. between menu items
      large: string; // e.g. wide (almost page-width) dividers
    };
  }
}

export const darkTheme: DefaultTheme = {
  name: "dark",
  dark: true,
  color: {
    brand: brandColors,
    palette,
  },
  background: {
    primary: palette.inkBlack,
    secondary: palette.oilBlack,
    tertiary: palette.graniteBlack,
    quaternary: palette.orcaBlack,
  },
  badge: {
    background: palette.anchorGrey,
  },
  border: {
    default: palette.satinBlack,
    focus: palette.black,
    contrast: palette.ironGrey,
  },
  button: {
    primary: {
      default: palette.electricBlue,
      hover: palette.oceanBlue,
      pressed: palette.deepSeaBlue,
      disabled: palette.ashGrey,
      text: palette.white,
    },
    secondary: {
      default: palette.charcoalBlack,
      hover: palette.onyxBlack,
      pressed: palette.smokeGrey,
      text: palette.white,
      disabled: palette.spanishGrey,
    },
    danger: {
      default: palette.tomatoRed,
      hover: palette.scarletRed,
      pressed: palette.crimsonRed,
      disabled: palette.ashGrey,
      text: palette.white,
    },
  },
  modal: {
    background: palette.oilBlack,
    header: palette.oilBlack,
    border: palette.anchorGrey,
  },
  toast: {
    primary: palette.almostBlack,
    secondary: palette.almostBlack,
    success: palette.kellyGreen,
    danger: palette.tomatoRed,
    warning: palette.pineappleYellow,
    info: palette.almostBlack,
  },
  text: {
    highlight: palette.ashGrey,
    default: palette.snowWhite,
    hover: palette.fogGrey,
    disabled: "#b4b4b4",
    muted: palette.ironGrey,
    secondary: palette.fogGrey,
  },
  link: {
    default: palette.cornflowerBlue,
    hover: palette.cornflowerBlue,
    active: palette.twilightBlue,
    visited: palette.cornflowerBlue,
  },
  input: {
    default: {
      text: palette.snowWhite,
      border: palette.ashGrey,
      background: palette.oilBlack,
    },
    hover: {
      text: palette.snowWhite,
      border: palette.anchorGrey,
      background: palette.ashGrey,
    },
    focused: {
      text: palette.snowWhite,
      border: palette.cornflowerBlue,
      background: palette.inkBlack,
    },
    invalid: {
      text: palette.blushRed,
      border: palette.scarletRed,
      // Same as the default color, so change them together
      background: palette.oilBlack,
    },
    disabled: {
      text: palette.ironGrey,
      border: palette.ironGrey,
      background: palette.inkBlack,
    },
    colorScheme: "dark",
  },
  tooltip: {
    text: palette.white,
    background: palette.almostBlack,
    hoverButtonText: palette.concreteGrey,
    shadow: palette.oilBlack,
  },
  header: {
    background: palette.obsidianBlack,
  },
  sidebar: {
    background: palette.obsidianBlack,
    border: palette.almostBlack,
    handle: {
      background: palette.almostBlack,
      text: brandColors.white,
    },
    item: {
      text: {
        default: palette.ironGrey,
        hover: brandColors.white,
        active: brandColors.white,
      },
      background: {
        hover: brandColors.white,
        active: brandColors.white,
      },
    },
  },
  subheader: {
    background: palette.oilBlack,
    border: palette.satinBlack,
  },
  projects: {
    card: {
      title: {
        default: palette.white,
        hover: palette.cornflowerBlue,
      },
    },
  },
  progressBar: {
    text: palette.ironGrey,
    progress: palette.electricBlue,
    background: palette.ashGrey,
  },
  overlayMenu: {
    border: palette.anchorGrey,
  },
  viewer: {
    background: palette.inkBlack,
  },
  divider: {
    small: palette.anchorGrey,
    large: palette.ashGrey,
  },
};

export const lightTheme: DefaultTheme = {
  name: "light",
  dark: false,
  color: {
    brand: brandColors,
    palette,
  },
  background: {
    primary: palette.white,
    secondary: palette.snowWhite,
    tertiary: palette.aluminumGrey,
    quaternary: palette.concreteGrey,
  },
  badge: {
    background: palette.anchorGrey,
  },
  border: {
    default: palette.concreteGrey,
    focus: palette.white,
    contrast: palette.anchorGrey,
  },
  button: {
    primary: {
      default: palette.electricBlue,
      hover: palette.oceanBlue,
      pressed: palette.deepSeaBlue,
      disabled: palette.concreteGrey,
      text: palette.white,
    },
    secondary: {
      default: palette.almostBlack,
      hover: palette.almostBlack,
      pressed: palette.almostBlack,
      disabled: palette.concreteGrey,
      text: palette.white,
    },
    danger: {
      default: palette.tomatoRed,
      hover: palette.scarletRed,
      pressed: palette.crimsonRed,
      text: palette.white,
      disabled: palette.concreteGrey,
    },
  },
  modal: {
    background: palette.white,
    header: palette.snowWhite,
    border: palette.aluminumGrey,
  },
  toast: {
    primary: palette.electricBlue,
    secondary: "#EAEAEA",
    success: "#3ea44f",
    danger: "#e52222",
    warning: "#ffb13d",
    info: palette.white,
  },
  text: {
    highlight: palette.white,
    default: palette.almostBlack,
    hover: palette.oilBlack,
    disabled: "#656565",
    muted: "#686868",
    secondary: palette.anchorGrey,
  },
  link: {
    default: palette.electricBlue,
    hover: palette.oceanBlue,
    active: palette.deepSeaBlue,
    visited: palette.electricBlue,
  },
  input: {
    default: {
      text: palette.almostBlack,
      border: palette.ironGrey,
      background: palette.white,
    },
    hover: {
      text: palette.almostBlack,
      border: palette.almostBlack,
      background: palette.twilightBlue,
    },
    focused: {
      text: palette.almostBlack,
      border: palette.electricBlue,
      background: palette.white,
    },
    invalid: {
      text: palette.scarletRed,
      border: palette.scarletRed,
      background: palette.blushRed,
    },
    disabled: {
      text: palette.ironGrey,
      border: palette.ironGrey,
      background: palette.concreteGrey,
    },
    colorScheme: "light",
  },
  tooltip: {
    text: palette.white,
    background: palette.almostBlack,
    hoverButtonText: palette.concreteGrey,
    shadow: palette.aluminumGrey,
  },
  header: {
    background: palette.satinBlack,
  },
  sidebar: {
    background: palette.satinBlack,
    border: palette.almostBlack,
    handle: {
      background: palette.almostBlack,
      text: brandColors.white,
    },
    item: {
      text: {
        default: palette.ironGrey,
        hover: brandColors.white,
        active: brandColors.white,
      },
      background: {
        hover: brandColors.white,
        active: brandColors.white,
      },
    },
  },
  overlayMenu: {
    border: palette.almostBlack,
  },
  subheader: {
    background: palette.aluminumGrey,
    border: palette.concreteGrey,
  },
  projects: {
    card: {
      title: {
        default: palette.almostBlack,
        hover: palette.electricBlue,
      },
    },
  },
  progressBar: {
    text: palette.ironGrey,
    progress: palette.electricBlue,
    background: palette.ashGrey,
  },
  viewer: {
    background: palette.white,
  },
  divider: {
    small: palette.ironGrey,
    large: palette.ironGrey,
  },
};

export function getTheme(t: string): DefaultTheme {
  switch (t) {
    case "light":
      return lightTheme;
    case "dark":
      return darkTheme;
    default:
      return lightTheme;
  }
}

// Static styles MUST be written in regular CSS as they are included in the DOM
// without any transpilation - SCSS/stylis will not work here.
const staticStyle = css`
  * {
    box-sizing: border-box;
    font-family: "Neufile Grotesk", Helvetica, Arial, sans-serif;
    line-height: 1.4em;
    scrollbar-width: 0;
    scrollbar-color: transparent;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
    width: 0;
  }

  *:focus {
    outline: transparent;
  }

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 400;
  }

  a {
    color: inherit;
    text-decoration: none;
    font-size: inherit;
  }

  p {
    margin: 0;
    padding: 0;
  }

  ${fonts}
`;

const DynamicStyle = createGlobalStyle`
  html, body {
    background-color: ${(p) => p.theme.background.primary};
    color: ${(p) => p.theme.text.default};
  }
`;

export const GlobalStyle = (): ReactElement => {
  const [_, setTheme] = useAtom(themeAtom);

  useEffect(() => {
    const style = document.createElement("style");
    style.innerHTML = staticStyle.join("").replaceAll(/\s\s+/g, " ").trim();

    document.head.appendChild(style);

    (window as unknown as { setTheme: typeof setTheme }).setTheme = setTheme;
  }, [setTheme]);

  return <DynamicStyle />;
};
