import React, { ReactNode } from "react";
import { t } from "@lingui/macro";
import { useRoute, useLocation } from "_/components/router";
import { TabBar, Tab } from "_/components/tab-bar";
import { MachineLastSeen } from "_/components/machine-listing";
import { BadgeKind, Badge } from "_/components/badge";
import { MachineDetailsProps } from "_/components/machine-details";
import { calculateMachineLastSeenKind } from "_/components/machine-listing";

import {
  Machine,
  StageChangeEvent,
  TelemetryEventKind,
  useMachine,
  useMachineEvents,
} from "_/data/machines";
import { machineRoutes, machineUrls } from "_/routes";

import * as S from "./styled";

export const MachineStatusBadge = ({
  machine,
  lastSeen,
  updatedAt,
}: MachineDetailsProps & {
  updatedAt: number;
  lastSeen: number | undefined;
}) => {
  const { data: events } = useMachineEvents({
    machineId: machine.id,
    from: undefined,
    to: undefined,
    limit: 1,
    kind: ["stateChange"] as TelemetryEventKind[],
    order: "desc",
  });

  if (!events) return null;

  const lastSeenKind = calculateMachineLastSeenKind({ lastSeen, updatedAt });
  const event = events[0] as StageChangeEvent;
  const status =
    lastSeenKind === "offline" || lastSeenKind === "never"
      ? "unknown"
      : (event?.data.state ?? "unknown");

  const { kind, statusLabel }: { kind: BadgeKind; statusLabel: string } = (
    {
      standby: { kind: "info", statusLabel: t`common.standby` },
      printing: { kind: "info", statusLabel: t`common.printing` },
      paused: { kind: "warning", statusLabel: t`common.paused` },
      complete: { kind: "success", statusLabel: t`common.complete` },
      cancelled: { kind: "muted", statusLabel: t`common.cancelled` },
      error: { kind: "error", statusLabel: t`common.error` },
      unknown: { kind: "error", statusLabel: t`common.unknown` },
    } as const
  )[status];

  return <Badge kind={kind}>{statusLabel}</Badge>;
};

type MachineHeaderProps = {
  machine: Machine;
};

export const MachineHeader = ({
  machine: { id: machineId },
}: MachineHeaderProps): ReactNode => {
  const [_location] = useLocation();

  const [overviewActive, _overviewParams] = useRoute(machineRoutes.index);
  const [thermalsActive, _thermalsParams] = useRoute(machineRoutes.thermals);
  const [eventsActive, _eventsParams] = useRoute(machineRoutes.events);
  const [configActive, _configParams] = useRoute(machineRoutes.configuration);

  // Call the useMachine hook again in order to get the updatedAt timestamp
  const { data: machine, dataUpdatedAt: updatedAt } = useMachine(machineId);

  if (!machine) return null;

  const lastSeen = machine.lastSeen
    ? new Date(machine.lastSeen).getTime()
    : undefined;

  return (
    <S.Wrapper>
      <S.Details>
        <S.LeftSection>
          <S.Meta>
            <S.Title>{machine.name}</S.Title>
            <S.Model>{machine.hardware.model}</S.Model>
          </S.Meta>
        </S.LeftSection>
        <S.RightSection>
          <S.RightSectionItems>
            <S.RightSectionItem>
              <S.Label>{t`common.status`}:</S.Label>
              <MachineStatusBadge
                machine={machine}
                lastSeen={lastSeen}
                updatedAt={updatedAt}
              />
            </S.RightSectionItem>
            <S.RightSectionItem>
              <S.Label>{t`common.machine-last-seen`}:</S.Label>
              <MachineLastSeen lastSeen={lastSeen} updatedAt={updatedAt} />
            </S.RightSectionItem>
          </S.RightSectionItems>
        </S.RightSection>
      </S.Details>
      <S.Divider />
      <TabBar>
        <Tab to={machineUrls.index(machine.id)} active={overviewActive}>
          {t`common.overview`}
        </Tab>
        <Tab to={machineUrls.thermals(machine.id)} active={thermalsActive}>
          {t`common.temperature`}
        </Tab>
        <Tab to={machineUrls.configuration(machine.id)} active={configActive}>
          {t`common.configuration`}
        </Tab>
        <Tab to={machineUrls.events(machine.id)} active={eventsActive}>
          {t`common.events`}
        </Tab>
      </TabBar>
    </S.Wrapper>
  );
};
