import React, { ReactElement, useEffect, useState, useRef } from "react";
import { useRoute, useLocation } from "_/components/router";
import { assocPath } from "ramda";
import { t } from "@lingui/macro";
import { useAtom, atom, PrimitiveAtom } from "jotai";

import { GCodePrintData } from "_/components/view-loaders/gcode/gcode-print";
import { UserAvatar } from "_/components/avatar";
import { Slider } from "_/components/slider";
import { Button } from "_/components/button";
import { MaterialName, SimulationIcon } from "_/components/materials";
import { ProjectEditForm } from "_/components/project-forms";
import { Modal, ViewerOverlayNotice } from "_/components/modal";
import { RadioButton, RadioGroup } from "_/components/radio";
import { ProgressBar } from "_/components/progress-bar";
import { BadUrlError } from "_/components/project-detail";

import { useCurrentUser, useUsers } from "_/data/users";
import {
  Project,
  Revision,
  useRevisionResources,
  SimulationType,
  useCreateSimulation,
} from "_/data/projects";
import { BlobRecord, useBlobContents } from "_/data/blob";
import { useMaterials } from "_/data/materials";

import { viewAtom, ViewMode, GcodeViewControls } from "_/state/viewer";

import { Tuple } from "_/types";
import { isValidUuid } from "_/utils";

import { projectRoutes, projectUrls } from "_/routes";

import GCodeWorker from "./gcode.worker";

import * as S from "./styled";

type GCodeControlsProps = {
  /**
   * Jotai atom to access the control state for the G-code visualization.
   */
  controlsAtom: PrimitiveAtom<GcodeViewControls>;

  /** Project (for showing edit settings modal) */
  project: Project;

  /** Revision (needed to populate project info box) */
  revision: Revision;
};

const GCodeControls = ({
  controlsAtom,
  project,
  revision,
}: GCodeControlsProps): ReactElement => {
  const [controls, setControls] = useAtom(controlsAtom);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSimModal, setShowSimModal] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const { data: materials } = useMaterials();
  const { data: users } = useUsers();

  const materialId0 = revision.materials?.[0];
  const mat0 = materials?.find((m) => m.id === materialId0);
  const materialId1 = revision.materials?.[1];
  const mat1 = materials?.find((m) => m.id === materialId1);

  // There are a few possibilities:
  // 1. All project materials are simulatable and optimizable, so we'll show
  //    the modal to help the user to choose whether to optimize.
  // 2. All project materials are simulatable, but at least one of them hasn't
  //    been validated for optimization. We'll show info in the simulation
  //    modal to explain why the optimization option isn't available.
  // 3. One or both materials are not yet validated for simulation, so we'll
  //    gray out the button and provide a tooltip to explain why it's disabled.
  const canSimulate =
    mat0?.capabilities.simulation && (!mat1 || mat1?.capabilities.simulation);

  const owner = users?.find((u) => u.id === project.ownerId);
  const lastModifiedBy = users?.find((u) => u.id === revision.creatorId);

  const range = controls.layer.range as Tuple<number>;

  if (!controls) {
    return <></>;
  }

  return (
    <>
      <S.LayerControlContainer>
        <S.SliderLabel>{t`common.layer`}</S.SliderLabel>
        <S.SliderContainer>
          <Slider
            min={0}
            max={range[1]}
            step={1}
            defaultValue={range}
            size="large"
            vertical
            onChange={(values) => {
              setControls((c) => {
                return assocPath(
                  ["layer", "value"],
                  values as Tuple<number>,
                  c
                );
              });
            }}
          />
        </S.SliderContainer>
      </S.LayerControlContainer>
      <S.RightSideContainer>
        <S.ProjectInfoContainer>
          {owner && (
            <S.ProjectInfoItem>
              <S.ProjectInfoLabel>{t`common.owner`}</S.ProjectInfoLabel>
              <S.ProjectInfoValue>
                <S.UserInfoContainer>
                  <S.UserName>{owner.name}</S.UserName>
                  <UserAvatar size="small" resource={owner} />
                </S.UserInfoContainer>
              </S.ProjectInfoValue>
            </S.ProjectInfoItem>
          )}
          {lastModifiedBy && lastModifiedBy !== owner && (
            <S.ProjectInfoItem>
              <S.ProjectInfoLabel>{t`common.last-modified-by`}</S.ProjectInfoLabel>
              <S.ProjectInfoValue>
                <S.UserInfoContainer>
                  <S.UserName>{lastModifiedBy.name}</S.UserName>
                  <UserAvatar size="small" resource={lastModifiedBy} />
                </S.UserInfoContainer>
              </S.ProjectInfoValue>
            </S.ProjectInfoItem>
          )}
          {mat0 && (
            <S.ProjectInfoItem>
              <S.ProjectInfoLabel>{t`common.primary-material`}</S.ProjectInfoLabel>
              <S.ProjectInfoValue>
                <MaterialName material={mat0} />
              </S.ProjectInfoValue>
            </S.ProjectInfoItem>
          )}
          {mat1 && (
            <S.ProjectInfoItem>
              <S.ProjectInfoLabel>{t`common.secondary-material`}</S.ProjectInfoLabel>
              <S.ProjectInfoValue>
                <MaterialName material={mat1} />
              </S.ProjectInfoValue>
            </S.ProjectInfoItem>
          )}
          <S.ProjectInfoButtonsItem>
            <Button
              kind="primary"
              onClick={() => canSimulate && setShowSimModal(true)}
              disabled={!canSimulate}
              helpContent={
                canSimulate ? undefined : (
                  <>
                    <div>{t`components.project-detail.simulation-disabled`}</div>
                    <ul>
                      {mat0 && !mat0.capabilities.simulation && (
                        <MaterialName material={mat0} />
                      )}
                      {mat1 && !mat1.capabilities.simulation && (
                        <MaterialName material={mat1} />
                      )}
                    </ul>
                    <S.MakeChildrenInline>
                      {t`components.project-detail.not-optimizable-footer`}
                      <SimulationIcon
                        capabilities={{ simulation: true, optimization: true }}
                      />
                    </S.MakeChildrenInline>
                    <S.MakeChildrenInline>
                      {t`components.project-detail.not-simulatable-footer`}
                      <SimulationIcon
                        capabilities={{ simulation: true, optimization: false }}
                      />
                    </S.MakeChildrenInline>
                  </>
                )
              }
            >
              {t`common.simulate`}
            </Button>
            <Button kind="secondary" onClick={() => setShowEditModal(true)}>
              {t`common.revise-project`}
            </Button>
          </S.ProjectInfoButtonsItem>
        </S.ProjectInfoContainer>
      </S.RightSideContainer>
      <SimModal
        showSimModal={showSimModal}
        setShowSimModal={setShowSimModal}
        revision={revision}
      />
      <Modal
        open={showEditModal}
        title={t`components.project-detail.revise-project`}
        onClose={isUploading ? undefined : () => setShowEditModal(false)}
      >
        <ProjectEditForm
          project={project}
          onComplete={() => setShowEditModal(false)}
          isUploading={isUploading}
          setIsUploading={setIsUploading}
        />
      </Modal>
    </>
  );
};

type GCodeLoaderProps = {
  /**
   * Manifest of the blob to load.
   */
  blobRecord: BlobRecord;

  /** Revision containing this G-code */
  project: Project;

  /** Revision containing this G-code */
  revision: Revision;
};

/**
 * Loads the G-code for the given blob into the viewer.
 */
const GCodeLoader = ({
  blobRecord,
  revision,
  project,
}: GCodeLoaderProps): ReactElement => {
  // Loading progress of the G-code file.
  const [loadProgress, setLoadProgress] = useState(0);
  const [parseProgress, setParseProgress] = useState(0);
  const [constructionProgress, setConstructionProgress] = useState(0);

  const blobId = blobRecord.id;

  // Reset the progress bars when loading a new G-code file.
  useEffect(() => {
    setLoadProgress(0);
    setParseProgress(0);
    setConstructionProgress(0);
  }, [blobId]);

  const {
    data: buffer,
    error: loadingError,
    isFetched,
  } = useBlobContents(blobRecord, setLoadProgress, true);

  const [_view, setView] = useAtom(viewAtom);

  const [controlsAtom, setControlsAtom] =
    useState<PrimitiveAtom<GcodeViewControls>>();

  const [noExtrusion, setNoExtrusion] = useState(false);

  const workerRef = useRef<Worker>();

  useEffect(() => {
    if (isFetched) {
      setLoadProgress(1);
    }
  }, [isFetched]);

  useEffect(() => {
    // clear the controls atom when the data changes so that the controls are reset
    setControlsAtom(undefined);
    if (!buffer) {
      setNoExtrusion(false);
      return;
    }

    const decoder = new TextDecoder("utf-8", { fatal: true });
    const result = decoder.decode(buffer);

    const worker = new GCodeWorker();

    worker.onmessage = (event) => {
      if (event.data.parseProgress) {
        setParseProgress(event.data.parseProgress);
      } else if (event.data.constructionProgress) {
        setConstructionProgress(event.data.constructionProgress);
      } else if (event.data.printData) {
        const printData = event.data.printData as GCodePrintData;

        // Check if the parsed G-code contains extrusion commands.
        // If not, display a message to the user.
        if (printData.totalSegments <= 0) {
          return setNoExtrusion(true);
        }

        const topLayerIndex = printData.topLayer;

        const cAtom = atom<GcodeViewControls>({
          layer: { range: [0, topLayerIndex], value: [0, topLayerIndex] },
        });

        setView({
          mode: ViewMode.Gcode,
          id: blobId,
          data: { printData },
          controlsAtom: cAtom,
        });

        setControlsAtom(cAtom);

        worker.terminate();
      }
    };

    worker.postMessage(result);

    workerRef.current = worker;

    return () => {
      if (workerRef.current) {
        workerRef.current.terminate();
      }
    };
  }, [buffer, blobId, revision, setView]);

  if (noExtrusion) {
    return (
      <ViewerOverlayNotice>{t`components.controls.prepare.no-extrusion`}</ViewerOverlayNotice>
    );
  }

  if (!controlsAtom) {
    return (
      <ViewerOverlayNotice title={t`components.controls.prepare.loading-gcode`}>
        <S.ProgressWrapper>
          <ProgressBar
            title={t`components.controls.prepare.loading-file`}
            value={loadProgress}
          />
          <ProgressBar
            title={t`components.controls.prepare.parsing-file`}
            value={parseProgress}
          />
          <ProgressBar
            title={t`components.controls.prepare.constructing-geometry`}
            value={constructionProgress}
          />
          {!!loadingError && t`components.controls.loading-error`}
        </S.ProgressWrapper>
      </ViewerOverlayNotice>
    );
  }

  return (
    <GCodeControls
      controlsAtom={controlsAtom}
      project={project}
      revision={revision}
    />
  );
};

type RevisionResourceLoaderProps = {
  /** Project containing this revision */
  project: Project;

  /** Revision to load resources for */
  revision: Revision;
};

/**
 * Loads the G-code for the given revision into the viewer and renders controls for it.
 */
const RevisionResourceLoader = ({
  project,
  revision,
}: RevisionResourceLoaderProps) => {
  const { data: resources } = useRevisionResources(revision);

  if (!resources) return null;

  const gcodeBlobRecord = resources.find((r) => r.kind === "gcode");

  if (!gcodeBlobRecord) {
    return (
      <ViewerOverlayNotice>{t`components.controls.prepare.no-gcode`}</ViewerOverlayNotice>
    );
  }

  return (
    <GCodeLoader
      blobRecord={gcodeBlobRecord}
      project={project}
      revision={revision}
    />
  );
};

type PrepareViewerControlPanelProps = {
  /**
   * Project to view.
   */
  project: Project;
};

const RevisionNotFoundError = (): ReactElement => (
  <ViewerOverlayNotice
    title={t`common.revision-not-found`}
  >{t`common.revision-not-found-description`}</ViewerOverlayNotice>
);

export const PrepareViewerControlPanel = ({
  project,
}: PrepareViewerControlPanelProps): ReactElement => {
  const [_match, params] = useRoute(projectRoutes.prepare);

  const revisionId = params?.revisionId;

  if (!revisionId) {
    return (
      <ViewerOverlayNotice>{t`components.controls.prepare.no-revisions`}</ViewerOverlayNotice>
    );
  }

  const revision = project.revisions.find((r) => r.id === revisionId);

  if (!revision) {
    return isValidUuid(revisionId) ? (
      <RevisionNotFoundError />
    ) : (
      <BadUrlError />
    );
  }

  return <RevisionResourceLoader project={project} revision={revision} />;
};

function SimModal({
  revision,
  showSimModal,
  setShowSimModal,
}: {
  revision: Revision;
  showSimModal: boolean;
  setShowSimModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [_location, setLocation] = useLocation();

  const { data: user } = useCurrentUser();
  const { data: materials } = useMaterials();

  const createSimulation = useCreateSimulation(revision);

  const materialId0 = revision.materials?.[0];
  const mat0 = materials?.find((m) => m.id === materialId0);
  const materialId1 = revision.materials?.[1];
  const mat1 = materials?.find((m) => m.id === materialId1);
  const canOptimize =
    mat0?.capabilities.optimization &&
    (!mat1 || mat1?.capabilities.optimization);

  const [simType, setSimType] = useState<SimulationType | undefined>(
    canOptimize ? undefined : "analyze"
  );
  const [simJobSent, setSimJobSent] = useState(false);
  const [encounteredError, setEncounteredError] = useState(false);

  const SimTypeLabel = ({
    label,
    description,
    disabled,
  }: {
    label: string;
    description: string;
    disabled?: boolean;
  }) => {
    return (
      <S.SimTypeOption $disabled={disabled}>
        <S.SimTypeLabel>{label}</S.SimTypeLabel>
        <S.SimTypeLongDescription>{description}</S.SimTypeLongDescription>
      </S.SimTypeOption>
    );
  };

  const handleSubmitSimJob = async () => {
    // Reset `encounteredError` to hide error message on retrying submit.
    setEncounteredError(false);

    if (!simType || !(mat0 || mat1)) {
      const fields = {
        user,
        simType,
        mat0,
      };
      const missingFields = Object.keys(fields).filter(
        (k) => fields[k] === undefined
      );

      console.error("Missing fields for sim job:", missingFields.join(", "));

      return;
    }

    try {
      setSimJobSent(true);
      const simulation = await createSimulation.mutateAsync({
        optimizeGcode: simType == "analyze+optimize",
      });
      setLocation(projectUrls.optimize(revision.projectId, simulation.job.id));
    } catch (e) {
      setSimJobSent(false);
      setEncounteredError(true);
      console.error("Error creating sim job:", e);
    }
  };

  // Final content on display in the modal.
  const bottomContent = encounteredError ? (
    <S.SimModalMessage>{t`components.controls.prepare.creating-sim-job-failed`}</S.SimModalMessage>
  ) : simJobSent ? (
    <S.SimModalMessage>{t`components.controls.prepare.creating-sim-job`}</S.SimModalMessage>
  ) : null;

  return (
    <Modal
      open={showSimModal}
      title={t`common.simulate`}
      onClose={() => {
        setEncounteredError(false);
        setShowSimModal(false);
      }}
    >
      <S.ModalItem>
        <S.SimTypeRadioGroupLabel>{t`common.simulation-type`}</S.SimTypeRadioGroupLabel>
        <RadioGroup name="simulationType" onChange={setSimType} value={simType}>
          <RadioButton
            value="analyze+optimize"
            disabled={!canOptimize}
            label={
              <>
                <SimTypeLabel
                  label={t`common.simulate-and-optimize-label`}
                  description={t`common.simulate-and-optimize-description`}
                  disabled={!canOptimize}
                />
                {!canOptimize && (
                  <S.NotOptimizableWarningBox>
                    <div>{t`components.project-detail.not-optimizable`}</div>
                    <ul>
                      {mat0 && !mat0.capabilities.optimization && (
                        <MaterialName material={mat0} />
                      )}
                      {mat1 && !mat1.capabilities.optimization && (
                        <MaterialName material={mat1} />
                      )}
                    </ul>
                    <S.MakeChildrenInline>
                      {t`components.project-detail.not-optimizable-footer`}
                      <SimulationIcon
                        capabilities={{ simulation: true, optimization: true }}
                      />
                    </S.MakeChildrenInline>
                  </S.NotOptimizableWarningBox>
                )}
              </>
            }
          />
          <RadioButton
            value="analyze"
            label={
              <SimTypeLabel
                label={t`common.simulate-only-label`}
                description={t`common.simulate-only-description`}
              />
            }
          />
        </RadioGroup>
      </S.ModalItem>
      <S.ButtonsRow>
        <Button kind="secondary" onClick={() => setShowSimModal(false)}>
          {t`common.cancel`}
        </Button>
        <Button kind="primary" disabled={!simType} onClick={handleSubmitSimJob}>
          {t`common.simulate-submit-job`}
        </Button>
      </S.ButtonsRow>
      {bottomContent}
    </Modal>
  );
}
