import React, { ReactNode, ReactElement } from "react";

import { Tooltip, TooltipProps } from "_/components/tooltip";

import * as S from "./styled";

type HelpProps = Pick<TooltipProps, "content" | "placement">;

export type FormFieldProps = {
  label?: string;
  hint?: string;
  help?: HelpProps;
};

/**
 * Show info icon above form field.
 * Hovering over it reveals a tooltip describing the control.
 */
export const Help = ({ content, placement }: HelpProps): ReactElement => {
  const infoIcon = <S.InfoIcon variant="Info" />;
  return (
    <Tooltip
      target={infoIcon}
      content={content}
      placement={placement}
      delay={0}
      wait={0}
    />
  );
};

/**
 * The Label component wraps the form field it is associated with.
 * It also renders the `<Help />` component, which is contained within the form field's label header.
 */
export const Label = ({
  text,
  help,
  children,
  className,
}: {
  text: string;
  help?: HelpProps;
  children: ReactNode;
  className?: string;
}): ReactElement => {
  return (
    <S.Label className={className}>
      <S.HeaderContainer>
        <S.LabelContainer>{text}</S.LabelContainer>
        {help ? <Help {...help} /> : null}
      </S.HeaderContainer>
      {children}
    </S.Label>
  );
};

/**
 * The Hint component is used to display error messages under the form field.
 */
export const Hint = ({
  text,
  invalid,
}: {
  text: string;
  invalid?: boolean;
}): ReactElement => {
  return <S.Hint $invalid={invalid}>{text}</S.Hint>;
};

/**
 * Function to add the `<Label />` and `<Help />` components above the form field.
 */
export const wrapLabelHeader = (
  children: ReactNode,
  label: string,
  help?: HelpProps,
  className?: string
) => (
  <Label text={label} help={help} className={className}>
    {children}
  </Label>
);

/**
 * Function to add the `<Hint />` component under the form field.
 */
export const appendHint = (
  node: ReactNode,
  hint: string,
  invalid?: boolean
) => (
  <S.HintWrapper>
    {node}
    <Hint text={hint} invalid={invalid}></Hint>
  </S.HintWrapper>
);
